.errorbox {
  width: 100%;
  height: 39vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  row-gap: 3%;
  box-shadow: 0 4px 4px #ccc;
}

.errorimage {
  height: 76%;
  width: 37%;
}

.errormessage {
  font-family: monospace;
  color: burlywood;
  font-size: 19px;
}
