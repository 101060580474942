.departmentwrapper {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  column-gap: 5%;
  padding-top: 2%;
  padding-bottom: 2rem;
}

.departmentdiv {
  width: 31px;
  height: 30px;
  border-radius: 7px;
  background-color: #c8aace;
  /* margin-right: 2%; */
}

.departmentwrapper > .right {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 45.2%;
  height: 36%;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 9.25061px 9.25061px 0px 0px;
}

.departmentwrapper .left {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 45.2%;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 9.25061px 9.25061px 0px 0px;
}

.inner {
  padding-left: 3%;
  width: 100%;
  background: #c8aace;
  border-radius: 7.25061px 7.25061px 0px 0px;
  padding: 10px;
}

.innertwo {
  width: 100%;
  background: #c8aace;
  border-radius: 7.25061px 7.25061px 0px 0px;
  padding: 10px;
  padding-left: 3%;
}

.innertwo p {
  height: 27px;
  left: 41px;
  top: 12.03px;
  margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
}

.inner p {
  height: 27px;
  left: 41px;
  top: 12.03px;
  margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  margin-left: 2%;
}

.human {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 70%;
  column-gap: 1rem;
}

.human > img {
  margin-right: 10px;
}

.human p {
  margin-top: 16px;
  font-weight: bold;
  overflow: hidden;
  text-align: left;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.testingwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 5%;
}

.testinginner {
  display: flex;
  justify-content: space-between;
  /* padding: 2%; */
  height: 44px;
  width: 90%;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  margin-top: 4%;
}

.testicon {
  display: flex;
  margin-top: 3px;
  column-gap: 11%;
  width: 29%;
  justify-content: end;
}

.testicon > img {
  width: 12%;
  cursor: pointer;
}

.department {
  width: 205px;
  height: 18px;
  margin-top: 3%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16.65px;
  line-height: 20px;

  color: #000000;
}

.departmentouter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3%;
  row-gap: 14px;
}

.departmentouter input {
  box-sizing: border-box;

  width: 100%;
  height: 43px;
  padding: 2%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-top: -2%;
}

.addbtn {
  width: 92px;
  height: 55.82px;
  color: #fff;
  background: #5d2475;
  border-radius: 11.2723px;
}

.addbtn:hover {
  background: #5d2475;
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {

  .departmentouter input {
    width: 95%;
  }

  .departmentouter {
    height: 12rem;
  }

  .departmentwrapper > .right {
    height: 42%;
  }
}
 */

@media only screen and (max-width: 768px) {
  .departmentdiv {
    width: auto;
    padding: 5%;
    display: flex;
  }

  .human {
    width: 55%;
  }

  .human > p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .departmentwrapper {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .testingwrap {
    padding: 3%;
  }

  .departmentwrapper .left {
    width: 90%;
  }

  .inner {
    height: auto;
  }

  .departmentwrapper > .right {
    width: 90%;
  }

  .testicon > img {
    width: 23%;
  }
}
