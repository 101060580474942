.contacttabs{
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.create-contact-container>  .contacttabs > .active {
    background: #FFC200  !important;
    color: #fff !important;
}

.contactwrap{
    width: 86%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    height: auto;
    margin-bottom: 5%;

}

.create-contact-container > .contacttabs > .tab {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    gap: 5px;
    width: 31%;
    height: 54px;
    border-radius: 10px;
    border: none;
    background: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #ccc;
}

.create-contact-container  {
    display: flex;
    justify-content: space-between;
    width: 30%;
}

.gallerypara{
    width: 10%;
    font-size: 3.2rem;
    height: 68px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    color: #540375;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
}


.photoimg{
    width: 31%;
    column-gap: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1%;
    row-gap: 2rem;
}

.photoimg > img{
    width: 31%;
}

.videoimg{
    width: 30%;
    column-gap: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1%;
    row-gap: 2rem;
}
.photoimg>img{
    width: 100%;
}

.iconstart{
    position: relative;
    width: 100%;
}

.iconstart > img{
    width: 100%;
}

.cardall{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
}

.playicon{
    position: absolute;
    margin-top: 4%;
    margin-left: 10%;
    padding: 1%;
    border-radius: 50%;
    background: #FF562F;
}

.allmedwrap{
    width: 84vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.videopreviewimg{
    width: 100%;
}

/* Media Query for Mobile */

@media only screen and (max-width: 600px) {

    .contactwrap{
        width: 100%;
    }
  .create-contact-container{
    width:79%;
    margin-left: 12%; 
  }

  .gallerypara {
    margin-left: 37%;
    font-size: 29px;
  }

  .videoimg{
    margin-top: 3%;
    width: 93%;
  }
  .photoimg {
    width: 95%;
  }
  .playicon{
    margin-top: 14%;
    margin-left: 35%;
  }
}


/* Media for Ipads */

/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .contactwrap {
        width: 109%;
    }
} */