

.inputbox{
    text-decoration: none;
    border: none;
    outline: none;
width: 300px;
border-radius: 10px;
background: #FFFFFF;
height: 100%;
padding: 22px;
padding-left: 34px;
height: 19.11px;
left: 25px;
top: 3.02px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
text-transform: capitalize;
color: #956A9D;
position: relative;
}