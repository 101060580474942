.uploadwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 886px;
    height: 653px;
    left: 645px;
    top: 131px;
    background: #FFFFFF;
    border: 1px solid #B6B6B6;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.selectfile {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 485px;
    height: 241px;
    left: 0px;
    top: 0px;
    background: #FAFAFA;
    border: 1px dashed #A4A4A4;
    border-radius: 20px;
}

.uploadbtn {
    margin-top: 3%;
    column-gap: 2%;
}

.filesupport {
    margin-left: -20%;
    width: 295px;
    height: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    text-transform: capitalize;
    color: #505050;
}

.con{
    margin-left: 34px;
}

@media only screen and (max-width: 768px) {
    .uploadwrapper{
        width: 96%;
    }

    .selectfile{
        width: 94%;
    }
}