.contactpara {
    width: 300px;
    height: 68px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    text-transform: capitalize;
    color: #540375;

}

.contactwrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
}

.insidecontact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contdesc {
    width: 59%;
    height: 58%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    text-transform: capitalize;
    color: #3D3D3D;
}

.contactcardwrap {
    display: flex;
    flex-wrap: wrap;
    width: 68%;
    column-gap: 2%;
    row-gap: 2rem;
    padding: 1%;
}

.contactbox {
    width: 408px;
    height: 433px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 30%;
    padding: 4%;
}

.contactbox>img {
    width: 52%;
}

.cardinnerbody {
    margin-top: 15%;
    margin-left: -8%;
}

h5 {
    width: 116%;
    font-size: 1.5rem;
}


/* @media only screen and (max-width: 600px) {
    .contactpara {
        font-size: 29px;
        margin-left: 43%;
    }

    .contdesc {
        width: 92%;
        font-size: 14px;
    }

    .contactcardwrap{
        width: 92%;
    }

    .contactbox{
        width: 100%;
    height: 323px;
    }

    .cardinnerbody{
        margin-top: 4%;
    }
} */