p {
  margin-bottom: 0 !important;
}

.padding-3 {
  padding: 3rem;
}

.highlight {
  background: #d0ced16c !important;
  /* font-weight: 800 !important; */
  border: 1px solid #ffffff !important;
  border-radius: 8px;
}

.react-calendar {
  border: none !important;
}

.react-calendar__tile--now {
  background: #c2b0c5 !important;
  color: white !important;
  border-radius: 8px !important;
}

.react-calendar button:hover {
  border-radius: 8px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  border-radius: 8px !important;
}

.pendingAsmtInfo {
  position: absolute;
  top: 50px;
  left: 10px;
}

.pendingAsmtInfo > span {
  font-weight: 600;
  color: grey;
  font-size: 14px;
}

.pendingAsmtInfo > span > span {
  font-weight: 400;
}

/* input::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 0 55px;
  cursor: pointer;
  border-radius: 3px;
} */

.tableContainer {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edu-right-side {
  background: #ececf0;
  width: 100%;
  padding: 15px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.asmt-search-filter {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.asmt-search-filter > form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.asmt-search-filter > form > button {
  background: #ffc200;
  color: #000000;
  line-height: normal;
  padding: 8px 15px;
  border-radius: 10px;
  border: none;
  margin-left: 10px;
  font-weight: 500;
}

.asmt-search-filter > form > .search-btn {
  margin-left: 2rem;
}

.asmt-search-filter > form > .input-field {
  margin-left: 10px;
}

.asmt-search-filter > form > .input-field > input,
.asmt-search-filter > form > .input-field > select {
  background: #ffffff;
  width: 100%;
  color: #c8aace;
  line-height: normal;
  padding: 8px 15px;
  border: 0 none;
  border-radius: 10px;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.teacherboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  margin-top: 40px;
  box-sizing: border-box;
  margin-left: 120px;
  width: 84.5%;
  height: auto;
  background: #ffffff;
  border: 1px solid #c8aace;
}

.teacherinnerbox {
  width: 100%;
  height: 68px;
  background: #c8aace;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding-left: 3rem;
}

.teacherinnerbox > p {
  font-size: 22px;
  color: #ffffff;
  margin: 0;
}

.asmt-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 50px;
  gap: 30px;
  width: 100%;
}

.asmt-container::after {
  content: "";
  width: 385.49px;
  /* justify-content: flex-start; */
}

.asmt-box {
  border: 0.814917px solid #c8aace;
  border-radius: 8.14917px;
  width: 385.49px;
  height: 295px;
  position: relative;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.asmt-box > .title-name {
  position: absolute;
  top: 18.55px;
  left: -16.26px;
  background: #ffc200;
  padding: 9px 12px 9px 12px;
  width: auto;
  border-radius: 1.55997px;
}

.left-asmt-box {
  padding-top: 3rem;
}

.left-asmt-box > .content {
  padding-bottom: 2rem;
}

.left-asmt-box > .content > p {
  font-size: 13.04px;
  margin: 0;
  color: #555555;
  width: 165%;
}

.left-asmt-box > .content > p > span {
  color: #000000;
  font-weight: 700;
}

.right-asmt-box {
  width: 50%;
}

.participated-container {
  display: flex;
  justify-content: space-between;
  width: 140%;
}

.asmt-box > .delete-btn {
  background: #ef4b5c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32.596683502197266px;
  width: 32.596683502197266px;
  right: 15px;
  bottom: 45px;
  padding: 8px;
  position: absolute;
}

.left-asmt-box .participated-container {
  font-size: 11.41px;
}

.left-asmt-box .participated-container .data-icon {
  display: flex;
  align-items: center;
  justify-content: start;
}

.left-asmt-box .participated-container span {
  font-size: 19.56px;
  font-weight: 600;
  text-align: center;
  padding-left: 6px;
}

.no-asmt {
  font-weight: 400;
  color: #7e7e7e;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
}

/* Assessment detail CSS */

.asmt-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  width: 100%;
}

.content-box {
  display: flex;
  align-items: flex-start;
  padding: 20px 30px;
  gap: 10px;
  width: 100%;
  background: #e1eaf9;
  border-radius: 5px;
}

.content-box > .content {
  width: 80%;
}

.content-box > .content > h2 {
  font-size: 28px;
  color: #000000;
  margin-bottom: 2rem;
}

.content-box > .content > p {
  font-size: 20px;
  margin: 0;
  width: 95%;
  color: #484848;
}

.content-box > .content > p > span {
  font-weight: 700;
  color: #000000;
}

.content-box > .img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  overflow: hidden;
}

.content-box > .img img {
  width: 100%;
  height: 100%;
}

.table-container {
  width: 95%;
}

.table-tabs {
  display: flex;
  height: 39px;
  width: 304px;
  border-radius: 0px;
  margin-bottom: 10px;
}

.table-tabs > .left,
.table-tabs > .right {
  border: 1px solid #ffc200;
  width: 50%;
  height: 100%;
  font-weight: 500;
  cursor: pointer;
}

.table-tabs > .active,
.table-tabs > div:hover {
  background: #ffc200;
}

.table-upper-ctrl {
  padding: 1rem 0;
}

.table-upper-ctrl > .left > select {
  margin: 0 10px;
  width: 73px;
  height: 37px;
  border: 1px solid #dcd9d9;
  border-radius: 5px;
}

.table-upper-ctrl > .right > label {
  margin-right: 10px;
  color: #5f5f5f;
}

.table-upper-ctrl > .right > input {
  border: 1px solid #dcd9d9;
  border-radius: 5px;
  width: 184px;
  height: 37px;
}

.table-lower-ctrl {
  height: auto;
  padding: 1.5rem 0;
}

.table-lower-ctrl > .left {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5f5f5f;
}

.table-lower-ctrl > .right > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #5f5f5f;
}

.table-lower-ctrl > .right > button {
  width: 46px;
  height: 42px;
  background: linear-gradient(180deg, #fbfbfb 0%, #e1e1e1 100%);
  border: 1px solid #0052cc;
  border-radius: 5px;
  margin: 0 10px;
}

.ongoingText {
  width: 86px;
  height: 32px;
  background: #ffc200;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}

/* Create Assessment */

.create-asmt-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
  gap: 30px;
  flex-direction: column;
  width: 100%;
}

.create-asmt-container > .tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}

.create-asmt-container > .tabs > .tab {
  /* Auto layout */
  margin: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 5px;
  width: auto;
  height: 54px;
  border-radius: 10px;
  border: none;
  background: #cacacb;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-bottom: 10px;
}

.create-asmt-container > .tabs > .active {
  background: #ffc200;
}

.create-asmt-container > .tabs > .tab > span {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50%;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}

.nav-btns {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-btns > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 5px;
  border: none;
  width: auto;
  height: 49px;

  /* background: #5d2475; */
  border-radius: 10px;
}

.nav-btns > .left {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}

.nav-btns > .right {
  margin-left: 1.5rem;
  background: #540375;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
}

/* Enter details component in CreateAssessment */

.asmt-type-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.asmt-type {
  margin: 0px 20px;
}
.asmt-type-box > .active {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px;
  gap: 26px;
  width: 100%;
  width: 409.58px;
  height: 122.58px;

  background: #c09fc6;
  border-radius: 10px;
}

.asmt-type-box > .inactive {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px;
  gap: 26px;
  width: 100%;
  width: 409.58px;
  height: 122.58px;

  background: #cacacb;
  border-radius: 10px;
}

.asmt-type-box > .asmt-type > label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.asmt-type-box > .asmt-type > label > span {
  font-family: "Inter";
  font-weight: 500;
  font-size: 32px;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.asmt-type-box > .asmt-type > label > span > span {
  font-size: 19px;
  /* or 22px */
  text-transform: capitalize;
  color: #ffffff;
  margin-top: -10px;
}

.asmt-type-box .asmt-type input:checked + .asmt-type {
  border: 10px solid green;
  color: #c8aace;
  background: #000;
}

.asmt-type-box > .asmt-type .img {
  border-radius: 50%;
  width: 82.58px;
  height: 82.58px;
  background: #ffffff;
}

.asmt-type-box > .asmt-type .img > img {
  height: 39px;
  width: 34px;
}

.asmt-type-box .asmt-type input {
  display: none;
}

.enter-details-container {
  width: 100%;
}

.enter-details-container > .form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.enter-details-container > .form > .input > input::placeholder {
  color: rgba(255, 255, 255, 0.616);
}

.enter-details-container > .form > .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 48%;
}

.enter-details-container > .form > .input > label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  /* identical to box height */
  text-transform: capitalize;
  color: #000000;
}

.enter-details-container > .form > .input > label > span {
  color: red;
}

.enter-details-container > .form > .input > input,
.enter-details-container > .form > .input > select {
  background: #c8aace;
  border-radius: 10px;
  font-weight: 700;
  padding: 10px;
  width: 100%;
  border: 0 none;
  color: #fff;
  font-size: 16px;
  line-height: normal;
  height: 46px;
}

.enter-details-container > .form > .input > input::placeholder,
.enter-details-container > .form > .input > select {
  font-family: "Inter";
  color: #ffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* text-transform: capitalize; */
}

.enter-details-container > .form > .input > select {
  appearance: none;
  background: #c8aace url(/public/images/icons/down-ward-arrow.svg) no-repeat
    right 10px center;
  background-size: 12px;
  padding-right: 20px;
  cursor: pointer;
}

.enter-details-container
  > .form
  > .input
  > input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(/public/images/icons/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

/* placeholder text style */
.enter-details-container
  > .form
  > .input
  input[type="date"]::-webkit-datetime-edit-text,
.enter-details-container
  > .form
  > .input
  input[type="date"]::-webkit-datetime-edit-month-field,
.enter-details-container
  > .form
  > .input
  input[type="date"]::-webkit-datetime-edit-day-field,
.enter-details-container
  > .form
  > .input
  input[type="date"]::-webkit-datetime-edit-year-field {
  color: #ffff;
  font-weight: 700;
  font-family: "Inter";
}

/* Add Question Component in CreateAssessment */

.add-questions {
  width: 100%;
}

.add-question {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.add-question > a > button,
.add-question > button {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 5px;

  width: auto;
  height: 49px;
  border: none;
  background: #ffc200;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;

  /* font */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #000000;
}

.add-questions > .table-container {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
}

.add-questions > .table-container > table {
  width: 100%;
}

.table-container td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15.72px;
  line-height: 19px;
  color: #000000;
  text-transform: capitalize;
}

.videoIconBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 9px;

  width: 33.71px;
  height: 34px;
  border: none;
  background: #ff562f;
  border-radius: 3.42593px;
}

.videoIconBtn > img {
  width: 15.71px;
  height: 20px;
}

.add-questions > .table-container > table input {
  height: 21px;
  width: 26px;
  border-radius: 2px;
}

.add-questions > .table-container > table .preview {
  height: 60px;
  width: 90.88235473632812px;
  border-radius: 5px;
}

.add-questions > .table-container > table .duration {
  height: 20px;
  width: 20px;
  border-radius: 0px;
  margin-bottom: 7px;
  margin-right: 10px;
}

.add-questions > .table-container > table .delete {
  width: 37px;
  height: 37px;
  border: none;
  background: #ff562f;
  border-radius: 3.42593px;
}

.add-questions > .table-container > table .delete > img {
  height: 15px;
  width: 15px;
}

/* Set AI Parameter component in Create Assessment */
.ai-tab-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ai-tab-container > .ai-tabs {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.ai-tab-container > .ai-tabs > button {
  border: none;
  width: 20%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ai-tab-container > .ai-tabs > .tab > div {
  box-sizing: border-box;
  width: 54px;
  height: 54px;
  background: linear-gradient(180deg, #f8f8fa 0%, #d7d7d7 100%);
  border: 3px solid #c8aace;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
}

.ai-tab-container > .ai-tabs > .tab > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #c9c9c9;
}

.ai-tab-container > .ai-tabs > .active > div,
.ai-tab-container > .ai-tabs > .tab > div:hover {
  box-sizing: border-box;
  width: 54px;
  height: 54px;
  background: #a07fa7;
  border: 3px solid #c8aace;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  color: #ffffff;
}

.ai-tab-container > .ai-tabs > .active > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  background: #ffffff;
}

/* Level form Component in Create Assessment */
.level-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 3rem 0;
}

.level-form > .input {
  width: 33%;
  display: flex;
  justify-content: center;
}

.level-form > .input > input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-top: 7px;
  accent-color: #ffc200 !important;
  margin-right: 10px;
  color: white;
}

.level-form > .input > label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: right;
  text-transform: capitalize;
  color: #000000;
}

/* Weightage form in Create Assessment */
.weightage-form {
  padding-top: 5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.weightage-form > div {
  width: 50%;
}

.weightage-form > .range-form {
  width: 60%;
}

.weightage-form > .range-form > .range-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
  /* border: 1px solid black; */
}

.range-input > .left {
  display: flex;
  align-items: center;
}

.range-input > .left > div {
  margin-right: 10px;
}

/* All range input CSS */
input[type="range"] {
  height: 0;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  /* animate: 0.2s; */
  width: 100%;
  height: 6px;
  left: 0px;
  top: 7.5px;
  cursor: pointer;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  width: 19px;
  height: 19px;
  border-radius: 23px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 13px;
}

input[type="range"]::-moz-range-thumb {
  width: 19px;
  height: 19px;
  border-radius: 23px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  /* animate: 0.2s; */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  border-radius: 26px;
}

input[type="range"]::-ms-fill-upper {
  border-radius: 26px;
}

input[type="range"]::-ms-thumb {
  width: 19px;
  height: 19px;
  border-radius: 23px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

/* Intro range input */

.introCircle {
  width: 15px;
  height: 15px;
  background: #5470c6;
  border-radius: 50%;
}

.intro > input[type="range"]::-webkit-slider-runnable-track {
  background: #5470c6;
}

.intro > input[type="range"]::-webkit-slider-thumb {
  background: #5470c6;
}

.intro > input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #5470c6;
}

.intro > input[type="range"]::-moz-range-track {
  background: #5470c6;
}

.intro > input[type="range"]::-moz-range-thumb {
  background: #5470c6;
}

.intro > input[type="range"]::-ms-fill-lower {
  background: #3071a9;
}

.intro > input[type="range"]::-ms-fill-upper {
  background: #3071a9;
}

.intro > input[type="range"]::-ms-thumb {
  background: #5470c6;
}

.intro > input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}

.intro > input[type="range"]:focus::-ms-fill-upper {
  background: #3071a9;
}

/* Main Context range input */
.mainContextCircle {
  width: 15px;
  height: 15px;
  background: #91cc75;
  border-radius: 50%;
}

.mainContext > input[type="range"]::-webkit-slider-runnable-track {
  background: #91cc75;
}

.mainContext > input[type="range"]::-webkit-slider-thumb {
  background: #91cc75;
}

.mainContext > input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #91cc75;
}

.mainContext > input[type="range"]::-moz-range-track {
  background: #91cc75;
}

.mainContext > input[type="range"]::-moz-range-thumb {
  background: #91cc75;
}

.mainContext > input[type="range"]::-ms-fill-lower {
  background: #91cc75;
}

.mainContext > input[type="range"]::-ms-fill-upper {
  background: #91cc75;
}

.mainContext > input[type="range"]::-ms-thumb {
  background: #91cc75;
}

.mainContext > input[type="range"]:focus::-ms-fill-lower {
  background: #91cc75;
}

.mainContext > input[type="range"]:focus::-ms-fill-upper {
  background: #91cc75;
}

/* Environment range input */
.environmentCircle {
  width: 15px;
  height: 15px;
  background: #fac754;
  border-radius: 50%;
}

.environment > input[type="range"]::-webkit-slider-runnable-track {
  background: #fac754;
}

.environment > input[type="range"]::-webkit-slider-thumb {
  background: #fac754;
}

.environment > input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #fac754;
}

.environment > input[type="range"]::-moz-range-track {
  background: #fac754;
}

.environment > input[type="range"]::-moz-range-thumb {
  background: #fac754;
}

.environment > input[type="range"]::-ms-fill-lower {
  background: #fac754;
}

.environment > input[type="range"]::-ms-fill-upper {
  background: #fac754;
}

.environment > input[type="range"]::-ms-thumb {
  background: #fac754;
}

.environment > input[type="range"]:focus::-ms-fill-lower {
  background: #fac754;
}

.environment > input[type="range"]:focus::-ms-fill-upper {
  background: #fac754;
}

/* Outro range input */
.outroCircle {
  width: 15px;
  height: 15px;
  background: #ed6060;
  border-radius: 50%;
}

.outro > input[type="range"]::-webkit-slider-runnable-track {
  background: #ed6060;
}

.outro > input[type="range"]::-webkit-slider-thumb {
  background: #ed6060;
}

.outro > input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ed6060;
}

.outro > input[type="range"]::-moz-range-track {
  background: #ed6060;
}

.outro > input[type="range"]::-moz-range-thumb {
  background: #ed6060;
}

.outro > input[type="range"]::-ms-fill-lower {
  background: #ed6060;
}

.outro > input[type="range"]::-ms-fill-upper {
  background: #ed6060;
}

.outro > input[type="range"]::-ms-thumb {
  background: #ed6060;
}

.outro > input[type="range"]:focus::-ms-fill-lower {
  background: #ed6060;
}

.outro > input[type="range"]:focus::-ms-fill-upper {
  background: #ed6060;
}

.weightage-form > .range-form > .range-input > .left {
  width: 30%;
  /* border: 1px solid red; */
}

.weightage-form > .range-form > .range-input > .right {
  width: 65%;
  /* border: 1px solid green; */
}

.weightage-form > .range-form > .range-input > .right > input {
  width: 90%;
  background: #000;
  margin-right: 10px;
}

/* Intro Form Component in Create Assessment */
.intro-form {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  margin-top: 2rem;
}

.intro-form::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

.intro-form::-webkit-scrollbar-track {
  box-sizing: border-box;

  position: absolute;
  width: 14px;
  height: 321.96px;
  left: 537px;
  top: 0px;

  border: 1px solid #d9d9d9;
}

.intro-form::-webkit-scrollbar-thumb {
  width: 14px;
  background: #ffc200;
}

.intro-form > .upper-part {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.intro-form > .upper-part > div {
  width: 50%;
}

.intro-form > .upper-part > div .input label {
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}

.intro-form > .upper-part > div > .input > label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}

.intro-form > .upper-part > div .input > .radios {
  padding-left: 8px;
  padding-bottom: 1rem;
  /* width: 16px;
  height: 16px; */
}

.intro-form > .upper-part input[type="checkbox"] {
  width: 20px;
  height: 20px;

  background: #ffffff;
  border: 2px solid #5a5a5a;
}

.intro-form > .lower-part > .input {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.intro-form > .lower-part > .input > .check {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.intro-form > .lower-part > .input > .check > input {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #5a5a5a;
  margin-right: 7px;
}

.intro-form > .lower-part > .input > .check > label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}

.intro-form > .lower-part > .input > input {
  margin-left: 28px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 10px;
  gap: 10px;
  width: 100%;
  height: 27px;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
}

/* 
@media screen and (min-width: 1024px) and (max-width: 1870px) {
  .create-asmt-container>.tabs {
    justify-content: center !important;
    flex-wrap: wrap !important;
    gap: 1rem !important;
    width: 100%;
  }

  .create-asmt-container > .tabs .tab::target-text {
    display: none !important;
  } 
  .create-asmt-container>.tabs>.tab {
    padding: 0 10px;
    font-size: 16px;
  }

  .create-asmt-container>.asmt-type-box {
    justify-content: center;
    flex-wrap: wrap !important;
    gap: 1rem;
  }

  .ai-tab-container>.ai-tabs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .ai-tab-container>.ai-tabs>button {
    min-width: 8rem;
    margin-top: 1rem;
  }

  .weightage-form>div {
    width: 100% !important;
  }

  .weightage-form {
    justify-content: center;
    flex-wrap: wrap;
  }

  .echarts-for-react {
    width: 100% !important;
  }

  .intro-form>.upper-part {
    justify-content: center;
    flex-wrap: wrap;
  }

  .intro-form>.upper-part>.left,
  .intro-form>.upper-part>.right {
    width: 100%;
  }
}


@media screen and (min-width: 1024px) and (max-width: 1330px) {
  .react-calendar__navigation {
    flex-wrap: wrap;
  }

  .react-calendar__navigation__arrow {
    margin-left: -10% !important;
  }

  .react-calendar__navigation__label__labelText {
    margin-left: -22% !important;
  }
}


@media screen and (min-width: 1331px) and (max-width: 1600px) {
  .react-calendar__navigation {
    flex-wrap: wrap;
  }

  .shadow-box {
    width: 100%;

  }

  .react-calendar__navigation__arrow {
    margin-left: -10% !important;
  }

  .react-calendar__navigation__label__labelText {
    margin-left: -22% !important;
  }
} */
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .create-asmt-container > .tabs {
    flex-wrap: wrap;
  }
  .create-asmt-container > .tabs > .tab {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .create-asmt-container > .tabs {
    flex-wrap: wrap;
  }
  .create-asmt-container > .tabs > .tab {
    margin-bottom: 10px;
  }
  .weightage-form {
    flex-direction: column;
  }
  .weightage-form > div,
  .weightage-form > .range-form {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .level-form > .input {
    justify-content: initial;
  }
  .row > * {
    padding-right: 0%;
  }
  .content-box > .img {
    border-radius: 23%;
  }
  .table-container {
    overflow: auto;
  }

  .enter-details-container > .form > .input > input,
  .enter-details-container > .form > .input > select {
    width: 95%;
  }
  .Modal_modal__xFUmu {
    width: 88% !important;
  }

  .create-asmt-container {
    padding: 3%;
  }

  .create-asmt-container > .tabs {
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-top: 4%;
  }

  .asmt-type-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .asmt-type-box > .active {
    width: 100%;
  }

  .asmt-type-box > .asmt-type > label > span {
    font-size: 28px;
  }

  .enter-details-container > .form > .input {
    width: 100%;
  }

  .ai-tab-container > .ai-tabs {
    width: 100%;
    flex-wrap: wrap;
    column-gap: 6%;
    justify-content: initial;
  }

  .ai-tab-container > .ai-tabs > .tab > span {
    /* width: 212%; */
    font-size: 8px;
  }

  .level-form {
    flex-wrap: wrap;
    margin-left: 48%;
  }

  .level-form > .input {
    width: 100%;
  }

  .weightage-form {
    flex-wrap: wrap;
  }

  .weightage-form > div {
    width: 100% !important;
    height: 377px !important;
  }

  .weightage-form > .range-form {
    width: 100%;
  }

  .weightage-form > .range-form > .range-input {
    flex-direction: column;
  }

  .intro-form {
    width: 100%;
  }

  .asmt-container {
    padding: 7%;
  }

  .asmt-box {
    width: 95%;
    height: 100%;
  }

  .nav-btns > .right {
    margin-bottom: 4%;
  }

  .intro-form > .upper-part {
    flex-wrap: wrap;
  }

  .intro-form > .upper-part > div {
    width: 100%;
  }
}
