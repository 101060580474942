.singleuserwrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  column-gap: 4%;
  row-gap: 25px;
}

.userdetail {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.singleuserwrapper .label{
  width: 100%;
}

.userabt {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.prevbtn{
  border:  1px solid #C9C9C9 !important;
}

.inputstyle input {
  width: 100%;
  height: 38px;
  padding: 0px 10px;
  border-radius: 10px;
  border: 1.17681px solid #c1c1c1;
  text-decoration: none;
  outline: none;
}

.fields {
  padding-left: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 7px;
}

.fieldsval {
  padding-left: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #000000;
  
}

.fieldsdate {
  padding-left: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #000000;
}

.fieldsemail {
  padding-left: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #000000;
}

.fieldspin {
  padding-left: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 48%;
}

.label label {
  padding-left: 4%;
}
.multdep .label {
    min-width: 250px;
}
.req {
  color: red;
  margin: 0px;
}

.labelreq {
  display: flex;
}

.btnmultiple {
  display: flex;
  column-gap: 1%;
  margin-top: 3%;
  width: 100%;
  justify-content: center;
}

.btnsingle {
  display: flex;
  column-gap: 2%;
  width: 100%;
  justify-content: center;
}

.submitbtn {
  background-color: #4D1A4E;
  color: #fff;
  border-radius: inherit;
  font-weight: 500;
}

.submitbtn:hover {
  background-color: #b567d5;
  color: #fff;
}

.multdep {
  display: flex;
  justify-content: center;
  column-gap: 2%;
  margin-top: 2%;
}
.multdep .label {
  min-width: 250px;
}
.userdetail {
  display: flex;
  flex-direction: column;
  width: 48%;
}
@media only screen and (max-width: 768px) {
  .userdetail{
    width: 100%;
  }

  .textfields {
    width: 100% !important;
  }

  .label {
    width: 100%;
  }

  .btnsingle {
    justify-content: flex-start;
  }

  .singleuserwrapper .label{
    width: 100%;
  }
  .multdep .label {
    min-width: 100%;
  }
  .userdetail {
    min-width:100%;
  }
  .multdep, .usermulti{
    flex-direction: column;
  }
  .multdep{
    row-gap: 25px;
  }
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {
  .multdep {
    flex-wrap: wrap !important;
  }

   .userabt{
    width: 12%;
  } 
} */
