.asseswrapper {
  width: 100%;
  padding: 15px;
}

.reactstar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactstar>p {
  margin-top: 11%;
}

.assesmentpara {
  width: 294px;
  height: 38px;
  left: 313px;
  top: 146px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 38px;
  text-transform: capitalize;
  color: #540375;
}

.btnwrapper {
  display: flex;
  justify-content: end;
  column-gap: 3%;
}

.assesmentdetail {
  border: 1.317px solid #cdcdcd;
  display: flex;
  justify-content: space-between;
  padding: 15.804px 30.291px;
  gap: 13.17px;
  width: 360px;
  height: 189.31px;
  background: #ffffff;
  border: 1.317px solid #cdcdcd;
}

.percentage {
  width: 85.8px;
  height: 85.8px;
  left: 29.9px;
  top: 0px;

  background: #e9edf2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.percentage p {
  margin-top: 17%;
  width: 62px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28.8003px;
  line-height: 35px;
  text-transform: capitalize;
  color: #4d1a4e;
}

.datedetail {
  width: 128px;
  height: 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22.0019px;
  line-height: 27px;
  text-transform: capitalize;
  color: #4d1a4e;
}

.timestamp {
  width: 128px;
  height: 21px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17.2872px;
  line-height: 21px;
  color: #4e4e4e;
}

.detailbtn {
  display: flex;
  flex-direction: column;
  row-gap: 8%;
}

.view {
  color: #fff;
  width: 156.62px;
  height: 34.23px;
  border-radius: 0% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.feedback {
  background-color: #fff;
  color: #f0bc15;
  width: 156.62px;
  height: 34.23px;
}

.assescard {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  /* background-color: #fff; */
  /* justify-content: space-around; */
  align-items: center;
  row-gap: 35px;
  padding-top: 26px;
  padding-bottom: 57px;
  margin-top: 3%;
  justify-content: flex-start;
  padding: 5%;
  column-gap: 5%;
}

/* .assescard{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    grid-auto-flow: column;
} */

.takesub {
  color: #fff !important;
  width: 121%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 13%;
  border-radius: inherit;
  font-weight: 500;
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .assesmentdetail {
    width: 48%;
    height: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .assesmentdetail {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .btnwrapper{
    column-gap: 7%;
  }
  
  .assesmentdetail {
    flex-wrap: wrap;
    height: auto;
    width: 90%;
    justify-content: center;
  }

  .assescard {
    justify-content: center;

  }
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {
    .assesmentdetail {
        width: 343px;
    }

    .assescard {
        padding: 1%;
        column-gap: 3%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1790px) {
    .assesmentdetail {
        width: 343px;
    }

    .assescard {
        padding: 1%;
        column-gap: 3%;
        display: flex;
        flex-wrap: wrap;
    }
} */