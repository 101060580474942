.termpara {
  /* width: 30%; */
  font-size: 3.2rem;
  height: 68px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  color: #540375;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  text-align: center;
}

.privpara {
  width: 30%;
  font-size: 3.2rem;
  height: 68px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  color: #540375;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}

.termwrap {
  background-color: #fff;
}

.terminner {
  padding: 4%;
}

.disclaimer {
  margin-top: 2%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: justify;
  text-transform: capitalize;
  line-height: 2%;
  color: #646464;
}

.detailterm {
  line-height: 2rem;
  margin-top: 2%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: justify;
  color: #646464;
}

.conditionhead {
  margin-top: 2%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #646464;
}

.termlink {
  color: #0057ff;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .termpara {
        width: 100%;
        font-size: 29px;
        margin-left: -1%;
        height: 10%;
    }

    .disclaimer {
        font-size: 14px;
    }

    .detailterm {
        font-size: 14px;
        line-height: 1.5rem;
    }

    .conditionhead {
        font-size: 18px;
    }

    .privpara {
        margin-left: 23%;
        width: 100%;
        font-size: 29px;
        height: 10%;
    }
}
