.videowrapper {
    width: 1214px;
    height: 550px;
    left: 0px;
    top: 59px;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.innervideowrap {
    width: 1214px;
    height: 56px;
    background: #C8AACE;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    padding-left: 2%;
    padding-top: 1%;
}

.innervideowrap p {
    width: 139px;
    height: 33px;
    left: 59px;
    top: 13px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 27.6203px;
    line-height: 33px;
    text-transform: capitalize;

    color: #FFFFFF;
}

.videopara {
    width: 139px;
    height: 33px;
    left: 59px;
    top: 13px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 27.6203px;
    line-height: 33px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.videocard {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10%;
}

.recordwrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 468px;
    height: 224px;
    left: 120px;
    top: 163px;
    background: #FFFFFF;
    border: 1px solid #DFDEDE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.recordwrap p {
    margin-top: 3%;
    width: 56px;
    height: 20px;
    left: 326px;
    top: 333px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16.1519px;
    line-height: 20px;
    text-transform: capitalize;
    color: #666565;
}

.videobtnwrap {
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2%;
}

.btncontinue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #540375;
}

@media only screen and (max-width: 768px) {
    .videowrapper{
        width: 93%;
    }

    .innervideowrap{
        width: 100%;
    }

    .videocard{
        flex-wrap: wrap;
        row-gap: 2rem;
    }

    .recordwrap{
        width: 75vw;
    }
}