.qnaddedwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    row-gap: 10px;
    width: 550px;
    height: 270px;
    background: #FFFFFF;
}

.qnaddedwrapper img {
    width: 75px;
    height: 75px;
    background: #FFFFFF;
}

.qnaddedwrapper p {
    width: 338px;
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #8D8D8D;
}

.okbtn {
    width: 71px;
    height: 39px;
    background: #7CD1F9;
    border: none;
}

.okbtn:hover {
    background: #7CD1F9;

}

