.tabss {
  width: 69%;
  display: flex;
  margin-bottom: 10px;
}

.create-report-container > .tabss > .active {
  background: #fff !important;
}

.reportwrap {
  width: 100%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  height: 5000px;
  margin-bottom: 5%;
}

.create-report-container > .tabss > .tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 5px;
  width: auto;
  height: 54px;
  border-radius: 10px;
  border: none;
  background: #ffc200;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-right: 15px;
}

.create-report-container {
  display: flex;
  justify-content: space-between;
}

.reportpercentage {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.percentagereport {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.percentagereport p {
  margin-right: 1rem;
  margin-left: 5px;
}

.belowthirty {
  padding-left: 6%;
}

.seventy {
  width: 25px;
  height: 10px;
  background: #008000;
}

.hundred {
  width: 25px;
  height: 10px;
  background: #f0bc15;
}

.fifty {
  width: 25px;
  height: 10px;
  background: #e91313;
}

.thirty {
  width: 25px;
  height: 10px;
  background: #9c9c9c;
  margin-left: 1%;
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {


    .create-report-container {
        flex-direction: column;
    }

    .tabss {
        width: 96%;
    }

    .reportpercentage {
        flex-direction: row;
        width: 71%;
        margin-top: 2%;
    }

    .percentagereport {
        width: 102%;
    }

    .hundred,
    .seventy,
    .fifty,
    .thirty {
        margin-right: 1%;
    }

} */
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .create-report-container {
    flex-direction: column;
  }
  .percentagereport p {
    font-size: 14px;
    width: 100px;
  }
  .reportpercentage {
    width: 100%;
    align-items: start;
    flex-direction: row;
    margin-top: 20px;
  }
  .belowthirty {
    padding-left: 0px;
  }
  .tabss {
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .create-report-container {
    flex-direction: column;
  }
  .reportpercentage {
    width: 100%;
    align-items: start;
    flex-direction: row;
    margin-top: 20px;
  }
  .belowthirty {
    padding-left: 0px;
  }
  .percentagereport p {
    font-size: 14px;
    width: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .percentagereport p {
    margin-right: 10px !important;
  }
  .create-report-container {
    flex-direction: column;
  }

  .tabss {
    flex-direction: column;
    row-gap: 1rem;
  }

  .reportpercentage {
    width: 72%;
    margin-top: 4%;
    column-gap: 5%;
  }

  .percentagereport {
    justify-content: initial;
  }

  .belowthirty {
    padding-left: 0 !important;
  }
}
