.addqncontainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  background: #ffffff;
  border: 0.906903px solid #c8aace;
  border-radius: 9.06903px 9.06903px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 95%;
  margin-top: 3rem;
  padding-bottom: 2rem;
}

.innerboxqn {
  width: 100%;
  height: 68px;
  background: #c8aace;
  border-radius: 7.06903px 7.06903px 0px 0px;
}

.innerboxqn p {
  margin-left: 3%;
  margin-top: 1.2%;
  width: 185px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25.049px;
  line-height: 30px;
  color: #ffffff;
}

.allquestions {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 15px;
}

.labelqn {
  width: 123px;
  height: 23px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18.8289px;
  line-height: 23px;
  color: #000000;
}

.textfields {
  box-sizing: border-box;
  width: 706.08px;
  height: 50px;
  left: 0px;
  top: 30.06px;
  background: #ffffff;
  border: 1.17681px solid #c1c1c1;
  border-radius: 23.5362px;
  padding-left: 2%;
  left: 18.81px;
  top: 46.06px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18.8289px;
  line-height: 23px;
  color: #c1c1c1;
}

.addQuestionInput {
  width: 100% !important;
}

.uploadbox {
  display: flex;
  align-items: center;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  column-gap: 10%;
  justify-content: center;
  width: 100%;
  height: 80px;
  background: #ffffff;
  border: 0.651952px solid #dfdede;
  box-shadow: 0px 2.60781px 2.60781px rgba(0, 0, 0, 0.25);
  border-radius: 13.039px;
}

.uploadbox p {
  width: 52px;
  height: 16px;
  left: 321.75px;
  top: 370.7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.039px;
  line-height: 16px;
  background: linear-gradient(180deg, #a749aa 0%, #551a56 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.qnrequired{
  color: red;
}

.buttonbox {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.nextbtn {
  width: 113.3px;
  height: 58.3px;
  background: #540375;
}

.previousbtn {
  width: 113.3px;
  height: 58.3px;
  border: 1px solid;
}

.nextbtn:hover {
  background: #540375;
}

.uploadfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px;
  width: 100%;
  height: 35px;
  color: black;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* text-transform: capitalize; */
  margin-left: 4%;
}

.supportfile {
  font-size: 12px;
  color: red;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1180px) and (max-width: 1600px) {
  .addqncontainer {
    width: 91%;
  }

  .innerboxqn {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .addqncontainer {
    width: 93%;
  }

  .innerboxqn {
    width: 100%;
  }

  .allquestions {
    width: 97%;
  }

  .textfields {
    width: 100%;
  }

  .uploadbox {
    width: 100%;
  }
}
