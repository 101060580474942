.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}
.closebtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.close {
  margin-left: auto;
  border: none;
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c8aace;
  color: white;
}

.h2 {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-transform: capitalize;
  color: #000000;
}

.btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.btn button {
  padding: 6px 25px;
  border-radius: 8px;
  border: none;
  margin-left: 10px;
}

.btn button:nth-of-type(1) {
  background: #7cd1f9;
  color: white;
}

.btn button:nth-of-type(1):hover {
  background: #02afff;
  transition: 200ms ease-in-out;
}

.btn button:nth-of-type(2):hover {
  background: #ebebeb;
  color: black;
  transition: 200ms ease-in-out;
}

.modal {
  position: fixed;
  top: 30vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
