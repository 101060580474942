.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 10vh;
  left: 12%;
  /* right: 5px; */
  width: 1500px;
  min-width: 500px;
  background-color: white;
  /* padding: 1rem; */
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

/* @media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
} */

.addParticipantsModal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.addParticipantsModal select,
.addParticipantsModal input {
  width: 481px;
  height: 46px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  padding: 0 1rem;
}
.addParticipantsModal select option {
  color: #956a9d;
  padding: 10px;
  background: inherit;
}

.addParticipantsModal input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #956a9d;
}

.pageBtns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.pageBtns > button {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14.5498px;
  color: #ffc200;
  width: 40.18px;
  height: 47.57px;
  font-weight: 600;
  background: #ffffff;
  border: 0.969988px solid #ffc200;
}

.pageBtns > button:hover {
  transition: 200ms ease-in-out;
  background: #ffc200;
  color: #ffffff;
}

.pageBtns > .active {
  background: #ffc200;
  border-radius: 2.46309px;
  color: #ffffff;
}

.pageBtns > button:nth-of-type(1) {
  border-radius: 9.69988px 1.23155px 1.23155px 9.69988px;
}

.pageBtns > button:nth-of-type(3) {
  border-radius: 1.23155px 9.69988px 9.69988px 1.23155px;
}

.submitBtnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.submitBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16.9084px 28px;
  gap: 5.64px;
  color: #ffffff;
  width: 92px;
  height: 55.82px;

  background: #5d2475;
  border-radius: 11.2723px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18.0357px;
  line-height: 22px;

  /* identical to box height */
  text-transform: capitalize;
}

.submitBtn:hover {
  background: #3d0057;
  transition: 200ms ease-in;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
