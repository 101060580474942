.headinnerwraprating {
    box-sizing: border-box;
    width: 98%;
    margin-left: 1%;
    height: 100vh;
    left: 0px;
    top: 56px;
    background: #ffffff;
    border: 1px solid #c8aace;
    border-radius: 10px 10px 0px 0px;
}

.mapwrap {
    width: 75%;
    height: 50vh;
}

.compscorewrap {
    padding: 3%;
    display: flex;
    column-gap: 4%;
}

.rating-tab {
    width: 62%;
    display: flex;
    height: 10vh;
    column-gap: 1%;
}

.create-rating-container>.rating-tab>.active {
    background: #0052CC !important;
    color: #fff !important;
}

.ratevidtwrap {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 5%;
    row-gap: 1%;
    display: flex;
    flex-direction: column;

}

.create-rating-container>.rating-tab>.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 5px;
    width: 33%;
    height: 54px;
    border: none;
    background: #FFC200;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
}

.create-rating-container {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    padding: 1.5%;
    height: 80px;
    border: 1px solid #E9E9E9;
    margin-bottom: 1%;
}

.globtable {
    height: 41vh;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
}

.rateglobe {
    background-color: #c8aace;
    height: 5vh;
}

@media only screen and (max-width: 768px) {

    .headinnerwraprating {
        height: auto;
    }

    .compscorewrap {
        flex-wrap: wrap;
        row-gap: 2rem;
    }

    .mapwrap {
        width: 100%;
    }

    .create-rating-container>.rating-tab>.tab {
        width: 56%;
    }
    
    .globtable{
        width: 108%;
    }
}