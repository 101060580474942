.listshow{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.listhide{
    display: none;
}

.userstyle{
height: 19px;
margin-bottom: 9%;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.2px;
color: #540375;

}

.nostyle{
    color: #ccc;
    margin-bottom: 9%;

}
.left-bar ul{
    white-space: nowrap;
}
.left-bar {
    overflow: hidden;
    transition: all 1s;
    
}
.left-bar .nav_text, .left-bar .bar-none{
    opacity: 1;
    transition: all 1s;
}
.left-bar.bar-change .nav_text, .left-bar.bar-change .bar-none{
    opacity: 0;
}
.wrapper {
    transition: all 1s;
}
