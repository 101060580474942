.summarycontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-bottom: 4rem;
  gap: 40px;
  width: auto;
  /* height: 437vh; */
  left: 393px;
  top: 275px;
  background: #fcfcfc;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #c8aace;
}

.summaryinner {
  width: 100%;
  height: 63px;
  left: 393px;
  top: 212px;
  background: #c8aace;
  border-radius: 7px 7px 0px 0px;
}

.reparatwo{
  font-size: 20px !important;
}

.reportonecon {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
}

.levelone {
  display: flex;
  justify-content: space-evenly;
}

.levelone > p {
  width: 33%;
  height: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
}

.levelone > p > span {
  margin-left: 1%;
  width: 165px;
  height: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
}

.leveltwo {
  display: flex;
  column-gap: 2%;
}

.cardwrap {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 10px;
  width: 75%;
  height: 316px;
  background: #fcfcfc;
  border: 1px solid #c8aace;
  border-radius: 15px;
  column-gap: 3%;
}

.studentimg {
  width: 336.74px;
  height: 250.2px;
  left: -8.86px;
  top: -6.63px;
  border-radius: 15px;
}

.outerdiv {
  margin-top: -5%;
}

.resemble {
  display: flex;
  column-gap: 2%;
  text-align: inherit;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-transform: capitalize;
  color: #540375;
}

.detail {
  margin-top: 3%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: capitalize;
  color: #8b8b8b;
}

.teachcon {
  display: flex;
  flex-direction: column;
}

.teachimg {
  width: 106px;
  height: 104px;
  border-radius: 50%;
  border: 2px solid #540375;
}

.resem {
  width: 75px;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #000000;
  margin-left: 18%;
}

.range > p {
  position: absolute;
}

.range {
  box-sizing: border-box;
  position: relative;
  width: 25%;
  height: 316px !important;
  background: #fcfcfc;
  border: 1px solid #c8aace;
  border-radius: 15px;
  margin-top: 0% !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avgcon {
  box-sizing: border-box;
  width: 300px;
  height: 50%;
  left: 0px;
  top: 49px;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 15px 15px 0px 0px;
  border-bottom-right-radius: 6%;
  border-bottom-left-radius: 6%;
}

.cardknob {
  display: flex;
  row-gap: 2rem;
  column-gap: 2%;
  flex-wrap: wrap;
  justify-content: center;
}

.avginner {
  height: 50px;
  left: 0px;
  top: 0px;
  background: #c8aace;
  border-radius: 13px 13px 0px 0px;
  padding: 5%;
}

.avginner > p {
  width: 140px;
  height: 22px;
  left: 18px;
  top: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: capitalize;
  color: #ffffff;
}

.knobtwo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
  margin-top: 1rem;
  min-height: 250px;
  max-height: 250px;
}

.knobtwo > img {
  width: 90%;
}

.knobtwo > p {
  position: absolute;
}

.levelthree {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 20px 17px;
  gap: 20px;
  width: 30%;
  height: 400px;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 15px;
  justify-content: space-between;
}

.innerintro {
  display: flex;
  justify-content: center;
  width: 102%;
  height: 13%;
  background: #c8aace;
  border-radius: 5px;
  align-items: center;
  padding-top: 4%;
  margin-left: auto;
  margin-right: auto;
}

.innerintrodesc{
  color: black;
}

.levelwrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  row-gap: 20px;
  margin-left: 4%;
}

.innerintro > p {
  height: 31px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  text-transform: capitalize;
  color: #ffffff;
}

.knobthree {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 7%; */
  /* margin-top: -3%; */
  margin-left: auto;
  margin-right: auto;
  height: 70%;
}

.knobthree svg {
  width: 80%;
}

.knobthree > p {
  position: absolute;
}

.cardContainer {
  display: flex;
  width: 95%;
  column-gap: 5%;
  flex-wrap: wrap;
  row-gap: 15%;
}

.criteria {
  width: 46%;
  margin: 2rem 0;
}

.criteriainner {
  background: #f3cc4d;
  border-radius: 5px;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 2%;
}

.imagewrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  margin-left: 2%;
}

.imagewrap img {
  margin-right: 2%;
}

.imagewrap > p {
  margin-top: 6%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  text-transform: capitalize;
  color: #000000;
}

.collapsewrap {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.studentwrap {
  display: flex;
  height: 76.7%;
}

.criteriasize {
  width: 206px;
  height: 240px;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c5c5c5;
}

.ratingcriteria {
  width: 100%;
}

.ratingcriteria > p {
  padding-top: 3%;
  padding-left: 2%;
  height: 20%;
  margin-bottom: 0rem;
  border: 0.5px solid #c5c5c5;
  /* width: 109%; */
}

.ratingcriteria > p:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.collapseicon {
  font-size: 209%;
  margin-right: 30px;
}

.collapseicon:hover {
  cursor: pointer;
}

.feature {
  font-weight: bold;
}

.repwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 7%;
}

.studyimg {
  width: 22%;
}

.line {
  margin-top: -7%;
  width: 44%;
}

.introimg {
  position: absolute;
  width: 20%;
  height: 20%;
  background-color: red;
  border-radius: 50%;
  width: 7%;
  height: 18%;
  background-color: red;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-left: -48%;
}

.intropara {
  position: absolute;
  margin-top: 150px;
  margin-left: -34%;
}

.bodyimg {
  position: absolute;
  width: 7%;
  height: 17%;
  background-color: red;
  border-radius: 50%;
  height: 17%;
  background-color: red;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 41%;
  margin-left: -46%;
}

.bodypara {
  position: absolute;
  margin-top: 52%;
  margin-left: -46%;
}

.facialimg {
  position: absolute;
  width: 7%;
  height: 17%;
  background-color: red;
  border-radius: 50%;
  height: 17%;
  background-color: red;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42%;
  margin-left: -14%;
}

.facialpara {
  position: absolute;
  margin-top: 53%;
  margin-left: -13%;
}

.langimg {
  position: absolute;
  width: 7%;
  height: 17%;
  background-color: red;
  border-radius: 50%;
  height: 17%;
  background-color: red;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42%;
  margin-left: 18%;
}

.languagepara {
  position: absolute;
  margin-top: 53%;
  margin-left: 19%;
}

.voiceimg {
  position: absolute;
  width: 7%;
  height: 17%;
  background-color: red;
  border-radius: 50%;
  height: 17%;
  background-color: red;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
  margin-left: 46%;
}

.voicepara {
  position: absolute;
  margin-top: 51%;
  margin-left: 47%;
}

.outroimg {
  position: absolute;
  width: 7%;
  height: 17%;
  background-color: red;
  border-radius: 50%;
  height: 17%;
  background-color: red;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-left: 48%;
}

.outropara {
  position: absolute;
  margin-top: 150px;
  margin-left: 34%;
}

.repara {
  width: 206px;
  height: 24px;
  left: 590.5px;
  top: 856px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
}

.repwrap p {
  font-size: 15px;
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {
  .leveltwo {
    flex-wrap: wrap;
    margin-top: 2%;
  }

  .cardwrap {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 2%;
  }

  .range {
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 25%;
    padding: 2%;
  }

  .cardknob {
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  .avgcon {
    height: 47%;
  }

  .introimg img {
    width: 39%;
  }

  .bodyimg img {
    width: 39%;
  }

  .facialimg img {
    width: 39%;
  }

  .outroimg img {
    width: 39%;
  }

  .langimg img {
    width: 39%;
  }

  .voiceimg img {
    width: 39%;
  }

  .intropara,
  .outropara,
  .bodypara,
  .languagepara,
  .voicepara,
  .facialpara {
    font-size: 12px;
  }

  .intropara,
  .outropara {
    margin-top: 15%;
  }

  .bodypara {
    margin-left: -51%;
  }

  .imagewrap {
    width: 64%;
  }

  .ratingcriteria p {
    font-size: 14px;
    height: 24%;
  }

  .criteriasize {
    height: 120%;
  }

  .summarycontainer {
    height: 500vh;
  }

  .levelone>p {
    width: 24%;
  }
} */
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .imagewrap > p {
    font-size: 20px;
    margin: 0px;
  }

  .imagewrap {
    width: 80%;
  }

  .ratingcriteria > p {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  .criteriasize {
    height: 300px;
  }

  .cardContainer {
    row-gap: 5%;
  }

  .leveltwo {
    flex-direction: column;
  }

  .cardwrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .avgcon {
    width: 32%;
  }

  .avginner {
    width: 100%;
  }

  .cardknob {
    padding: 15px;
  }

  .studyimg {
    width: 250px;
    margin-left: -21px;
  }

  .line {
    width: 400px;
    margin-top: -12%;
  }

  .introimg,
  .bodyimg,
  .facialimg,
  .langimg,
  .voiceimg,
  .outroimg {
    width: 80px;
    height: 80px;
  }

  .knobtwo > img {
    width: 150px;
  }

  .introimg {
    top: 260px;
    left: 0px;
    margin: 0px;
  }

  .bodyimg {
    margin-left: 0px;
    left: -31px;
    top: 221px;
  }

  .facialimg {
    margin-top: 464px;
    left: 100px;
    margin-left: 0px;
  }

  .langimg {
    margin-top: 464px;
    left: 240px;
    margin-left: 0px;
  }

  .voiceimg {
    left: auto;
    top: 233px;
    right: -60px;
  }

  .outroimg {
    margin-left: 0px;
    left: auto;
    top: 230px;
    right: -35px;
  }

  .intropara {
    top: 345px;
    left: 16px;
    margin: 0px;
  }

  .bodypara {
    margin-left: 0px;
    left: -55px;
    top: 265px;
  }

  .facialpara {
    margin-top: 595px;
    left: 61px;
    margin-left: 0px;
  }

  .languagepara {
    margin-top: 599px;
    left: 240px;
    margin-left: 0px;
  }

  .voicepara {
    left: auto;
    top: 233px;
    right: -60px;
  }

  .voicepara {
    left: auto;
    top: 277px;
    right: -46px;
  }

  .outropara {
    margin-left: 0px;
    left: auto;
    top: 210px;
    right: -24px;
  }

  .summarycontainer {
    gap: 60px;
  }

  .range {
    height: 200px;
    width: 40%;
  }

  .innerintro > p {
    height: 20px;
    font-size: 17px;
    line-height: initial;
  }

  .studentwrap {
    display: block;
  }

  .criteriasize {
    height: 70px;
    width: 100%;
  }

  .summarycontainer {
    height: auto;
  }

  .criteria {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .range {
    height: 200px;
    width: 40%;
  }

  .imagewrap > p {
    font-size: 20px;
    margin: 0px;
  }

  .imagewrap {
    width: 80%;
  }

  .ratingcriteria > p {
    height: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .criteriasize {
    height: auto;
    width: 100%;
  }

  .cardContainer {
    row-gap: 5%;
  }

  .leveltwo {
    flex-direction: column;
  }

  .cardwrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .avgcon {
    width: 32%;
  }

  .avginner {
    width: 100%;
  }

  .cardknob {
    padding: 15px;
  }

  .studyimg {
    width: 250px;
    margin-left: -21px;
  }

  .line {
    width: 400px;
    margin-top: -12%;
  }

  .introimg,
  .bodyimg,
  .facialimg,
  .langimg,
  .voiceimg,
  .outroimg {
    width: 80px;
    height: 80px;
  }

  .knobtwo > img {
    width: 150px;
  }

  .introimg {
    top: 260px;
    left: 0px;
    margin: 0px;
  }

  .bodyimg {
    margin-left: 0px;
    left: -31px;
    top: 221px;
  }

  .facialimg {
    margin-top: 464px;
    left: 100px;
    margin-left: 0px;
  }

  .langimg {
    margin-top: 464px;
    left: 240px;
    margin-left: 0px;
  }

  .voiceimg {
    left: auto;
    top: 233px;
    right: -60px;
  }

  .outroimg {
    margin-left: 0px;
    left: auto;
    top: 230px;
    right: -35px;
  }

  .intropara {
    top: 345px;
    left: 16px;
    margin: 0px;
  }

  .bodypara {
    margin-left: 0px;
    left: -55px;
    top: 265px;
  }

  .facialpara {
    margin-top: 595px;
    left: 61px;
    margin-left: 0px;
  }

  .languagepara {
    margin-top: 599px;
    left: 240px;
    margin-left: 0px;
  }

  .voicepara {
    left: auto;
    top: 233px;
    right: -60px;
  }

  .voicepara {
    left: auto;
    top: 277px;
    right: -46px;
  }

  .outropara {
    margin-left: 0px;
    left: auto;
    top: 210px;
    right: -24px;
  }

  .summarycontainer {
    gap: 60px;
    height: auto;
  }

  .studentwrap {
    display: block;
  }

  .criteria {
    margin-bottom: 20px;
  }

  .innerintro > p {
    height: 20px;
    font-size: 17px;
    line-height: initial;
  }

  .tabss {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .levelone > p {
    font-size: 14px;
  }

  .levelone > p > span {
    font-size: 14px;
  }

  .leveltwo {
    flex-direction: column;
    width: 100%;
    margin-top: 5%;
    row-gap: 1rem;
  }

  .knobtwo > img {
    height: 146px;
  }

  .cardwrap {
    flex-direction: column;
    width: 100%;
    height: 78%;
    margin-left: 0%;
  }

  .imagewrap > p {
    margin-top: 18px;
  }

  .resemble {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .desc {
    width: 85%;
    margin-left: 4%;
    height: auto;
  }

  .detail {
    width: 327px;
  }

  .aivideo {
    width: 93%;
    height: 47%;
    margin-left: -9%;
  }

  .aivideo video {
    width: 100%;
    height: 100%;
    border-radius: 5% !important;
  }

  .range {
    width: 100%;
    padding: 6%;
  }

  .avgcon {
    height: 225px;
  }

  .studyimg {
    width: 63px;
  }

  .line {
    width: 186px;
    margin-top: -28px;
  }

  .repwrap > p {
    font-size: 9px !important;
  }

  .repara {
    margin-left: 122px;
  }

  .introimg {
    width: 39px;
    height: 41px;
    margin-top: 33px;
    margin-left: -213px;
  }

  .introimg img {
    width: 18px;
  }

  .intropara {
    font-size: 9px;
    margin-left: -140px;
    margin-top: 57px;
  }

  .bodyimg {
    width: 39px;
    height: 41px;
    margin-top: 175px;
    margin-left: -180px;
  }

  .bodyimg img {
    width: 18px;
  }

  .bodypara {
    font-size: 9px;
    margin-left: -209px;
    margin-top: 244px;
    width: 23%;
  }

  .langimg {
    width: 39px;
    height: 41px;
    margin-top: 176px;
    margin-left: 66px;
  }

  .langimg img {
    width: 18px;
  }

  .languagepara {
    font-size: 9px;
    width: 76px;
    margin-left: 91px;
    margin-top: 256px;
  }

  .facialimg {
    width: 39px;
    height: 41px;
    margin-top: 175px;
    margin-left: -59px;
  }

  .facialimg img {
    width: 18px;
  }

  .facialpara {
    font-size: 9px;
    width: 65px;
    margin-top: 262px;
  }

  .voiceimg {
    width: 39px;
    height: 41px;
    margin-top: 173px;
    margin-left: 185px;
  }

  .voiceimg img {
    width: 18px;
  }

  .voicepara {
    font-size: 9px;
    margin-top: 250px;
    margin-left: 189px;
  }

  .outroimg {
    width: 39px;
    height: 41px;
    margin-top: 35px;
    margin-left: 216px;
  }

  .outroimg img {
    width: 18px;
  }

  .outropara {
    font-size: 9px;
    margin-left: 136px;
    margin-top: 57px;
  }

  .levelthree {
    width: 100%;
    height: 14%;
  }

  .criteria {
    width: 100%;
  }

  .cardContainer {
    row-gap: 5rem;
    margin-top: 22%;
  }

  .imagewrap {
    width: 73%;
  }

  .ratingcriteria > P {
    font-size: 69%;
  }

  .summarycontainer {
    height: 810vh;
  }

  .outerdiv {
    margin-top: 0%;
  }
}
