.reccon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    left: 0px;
    top: 0px;
    padding: 1%;
    background: #C8AACE;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
}

.recheadinnerwrap {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    left: 0px;
    top: 56px;
    background: #FFFFFF;
    border: 1px solid #C8AACE;
    border-radius: 10px 10px 0px 0px;

}

.areawrap {
    padding: 20px;
    display: flex;
    column-gap: 2%;

}

.recimg {
    position: relative;
    display: flex;
    justify-content: center;
}

.recimg>img {
    height: 150px;
}

.playwrap {
    position: absolute;
    width: 75px;
    height: 75px;
    margin-top: -8%;
    margin-left: 6%;
    background: #FF562F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playimgrec {
    width: 30%;

}

.recard {
    border: 2px solid red;
    box-sizing: border-box;
    width: 24%;
    height: 75%;
    background: #FFFFFF;
    border: 1px solid #C8AACE;
    padding: 10px;
}

.movecard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.pararec {
    height: auto;
    left: 10.32px;
    top: 303.7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5074px;
    line-height: 140%;
    text-transform: capitalize;

    color: #000000;
}

.movepara {
    width: 54px;
    height: 23px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16.5074px;
    line-height: 140%;
    text-transform: capitalize;
    color: #000000;
}

.recimg>video {
    width: 100%;
    height: 100%;
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {

    .recard {
        width: 32%;
        height: 30vh; 
        padding: 15px;
    }

    .pararec {
        width: 86%;
    }

    .recheadinnerwrap {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }

    .areawrap {
        flex-wrap: wrap;
        row-gap: 2rem;
    }

    .recimg> video {
        height: 19vh;
    }
} */
@media screen and (min-width:1024px) and (max-width:1200px){
    .recimg>img {
        height: 100px;
    }
    .recard {
        width: 49%;
        margin-bottom: 15px;
    }
    .areawrap {
        flex-wrap: wrap;
    }
}
@media screen and (min-width:769px) and (max-width:1024px){
    .recimg>img {
        height: 100px;
    }
    .recard {
        width: 49%;
        margin-bottom: 15px;
    }
    .areawrap {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 768px) {
    .areawrap {
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .recard {
        width: 100%;
    }
}