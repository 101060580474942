body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  background-color: #f2f2f2;
}

.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border: none;
  padding: 10px 15px;
  font-size: 1.5rem;
  border-radius: 20px;
  background: #4d1a4e;
  color: white;
  font-weight: 800;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1140px;
}
.header-fix {
  position: sticky;
  top: 0;
  z-index: 9;
}
a {
  text-decoration: none;
}

.aboutVideoHome {
  border-radius: 26px;
  overflow: hidden;
  width: 76%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 8.4px 7.4px 3.2px;
}

.custom-navbar {
  background: #fff;
  padding: 0;
}
.custom-navbar li a {
  font-size: 15px;
  font-weight: bold;
  color: #000;
}
.custom-navbar li a:hover,
.custom-navbar li a.active {
  color: #f4c018;
  border-bottom: 2px solid #f4c018;
  padding-bottom: 0px;
}
.wrap,
.section-wrap {
  width: 100%;
  clear: both;
}
.section-wrap {
  padding: 50px 0;
}
.top-bar {
  background: #4d1a4e;
  padding: 5px 0;
}
.top-bar a {
  color: #fff;
  font-size: 13px;
}
.socialmedia {
  display: flex;
}
.socialmedia ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.socialmedia ul li {
  margin: 0 10px;
}
.socialmedia ul li img {
  height: 15px;
  width: auto;
}
.contact-info a {
  display: inline-block;
  margin-right: 30px;
}
.contact-info a:last-child {
  margin-right: 0;
}
.contact-info a img {
  height: 15px;
  width: auto;
  margin: 0 3px 0 0;
}
.socialmedia a.sign-up {
  /* background: #ffffff; */
  /* color: #4d1a4e; */
  color: white;
  box-shadow: 0px 2.40705px 6.01762px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  text-decoration: none;
  width: 30px;
  height: 30px;
  /* padding: 2px 15px; */
  padding-top: 5px;
  font-size: 12px;
}

.socialmedia a.sign-out {
  background: #ffff;
  border-radius: 10px;
  padding: 2px 10px;
  color: grey;
}

.banner {
  position: relative;
  z-index: 1;
}
.banner:before {
  content: "";
  z-index: 5;
  background: linear-gradient(
    0deg,
    #f2f2f2 36.67%,
    rgba(242, 242, 242, 0) 86.67%
  );
  width: 100%;
  position: absolute;
  height: 60px;
  bottom: 0;
  left: 0;
}
.banner ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.banner ul li {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner ul li img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .banner .container {
    position: absolute;
    z-index: 9;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.banner .carousel-indicators {
  margin: 0;
  bottom: 70px;
}
.banner .carousel-control-prev,
.banner .carousel-control-next {
  display: none;
}
.banner .carousel-indicators button {
  padding: 0;
  margin: 0 6px;
  background: rgb(244, 192, 24);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-transform: capitalize;
}
.banner .container h2 {
  color: #f4c018;
  font-size: 30px;
  text-shadow: 2px 2px 0px rgb(0, 0, 0);
  font-weight: bold;
}
.banner .container p {
  color: #540375;
  text-transform: capitalize;
  font-size: 30px;
  line-height: 1.2;
}

.heading-yellow {
  color: #f4c018;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 25px;
}
.page-heading {
  color: #540375;
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 30px;
}

.contact-btn a {
  background: #4d1a4e;
  color: #fff !important;
  cursor: pointer;
  font-size: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  border-radius: 25px;
  display: inline-block;
  padding: 10px 20px;
  text-transform: capitalize;
}

.ourStakeholders .react-multiple-carousel__arrow {
  display: none;
}

.step-btn {
  margin-left: 90px;
}

ul.steps-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.steps-wrap li {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 25px;
}
ul.steps-wrap li .step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  background: #f29924;
  font-size: 20px;
  margin: 0 30px 0 0;
}
ul.steps-wrap li .num-wrap {
  width: calc(100% - 90px);
}
ul.steps-wrap li .num-wrap h2 {
  font-size: 25px;
  color: #540375;
  font-weight: bold;
  margin: 0 0 6px 0;
}
ul.steps-wrap li .num-wrap p {
  color: #656565;
  line-height: 1.2;
  margin: 0;
}

.round-img img {
  border-radius: 30px;
  max-width: 400px;
  width: 100%;
  box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.1);
}

.offerings-image {
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 10px;
}
ul.steps-row {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
}
ul.steps-row li {
  width: calc(50% - 15px);
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-bottom: 0;
}
.training-section .card {
  padding: 35px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  height: calc(100% - 30px);
  border: none;
  border-radius: 0;
}
.training-section .card img {
  width: 100%;
  margin-bottom: 24px;
}
.training-section .card h2 {
  color: #540375;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.training-section .card p {
  color: #656565;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0;
}
.faq-box {
  background: #552469;
  height: calc(100% - 30px);
  margin-top: 30px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
  padding: 35px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.faq-box h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.faq-box p {
  color: #f4c018;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}

.section-circle .section-wrap {
  background: url(/public/images/circle.svg) no-repeat 0 0;
}
.section-circle .section-wrap:nth-child(2n) {
  background: url(/public/images/circle-right.svg) no-repeat right 0;
}

h2.center-lines {
  align-items: center;
  display: flex;
  justify-content: center;
}
h2.center-lines span {
  padding: 0 10px;
  display: inline-block;
  position: relative;
}
h2.center-lines:before {
  content: "";
  background: #f4c018;
  width: 100%;
  max-width: 150px;
  height: 2px;
  display: block;
}
h2.center-lines:after {
  content: "";
  background: #f4c018;
  width: 100%;
  max-width: 150px;
  height: 2px;
  display: block;
}
h2.left-lines {
  display: flex;
  align-items: center;
}
h2.left-lines span {
  padding: 0 10px 0 0;
  white-space: nowrap;
}
h2.left-lines:after {
  content: "";
  background: #f4c018;
  width: 100%;
  height: 2px;
  margin: 0;
}

h2.right-lines {
  display: flex;
  align-items: center;
}
h2.right-lines span {
  padding: 0 10px 0 0;
  white-space: nowrap;
  width: 100%;
}
h2.right-lines:before {
  content: "";
  background: #f4c018;
  width: calc(50% - 15px);
  height: 2px;
  margin: 0;
  position: absolute;
  left: 15px;
  right: auto;
}

.right-section > div {
  order: 2;
}
.right-section > div:nth-child(2) {
  order: 1;
}
.slider-box {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
}
.slider-box .slider-img {
  margin: 0 0 20px;
}

.slider-box h3 {
  font-size: 25px;
  color: #540375;
  margin: 0 0 20px;
}
.slider-box .sllider-text p {
  font-size: 15px;
  color: #656565;
  line-height: 1.4;
  font-size: 16px;
}
.slider-box p:last-child {
  margin: 0;
}

.border-top-gray {
  border-top: 3px solid #d9d9d9;
}

.img-slider {
  align-items: center;
  display: flex;
  padding: 5px;
  border: 1px solid #f29924;
  box-shadow: none;
}

.img-slider img {
  width: 100%;
  height: auto;
}
.img-slider-wrap .react-multi-carousel-dot-list {
  display: none;
}

.border-bottom-yellow {
  border-bottom: 3px solid #f4c018;
  margin-bottom: 30px;
}

.navbar-toggler-icon {
  background: url(/public/images/menu.png) no-repeat center center;
  background-size: 100% auto;
}
.footer-wrap {
  background: #4a164b;
  padding: 50px 0;
  color: #fff;
}
.footer-wrap h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 15px;
}
.footer-wrap h2 span {
  display: inline-block;
  padding-right: 45px;
  position: relative;
  color: #ffff;
}
.footer-wrap h2 span:before {
  content: "";
  background-color: #f4c018;
  height: 2px;
  width: 40px;
  position: absolute;
  right: 0;
  top: 13px;
}
.footer-wrap ul {
  margin: 0;
  padding: 0;
}
.footer-wrap ul li {
  list-style: none;
  margin: 0 0 10px;
}
.footer-wrap ul li a {
  color: #fff;
  font-size: 12px;
}
.footer-wrap .know-more {
  background: #f29924;
  display: inline-block;
  padding: 7px 15px;
  color: #fff;
  border-radius: 50px;
  text-decoration: none;
}
.footer-note {
  padding: 15px 0;
}
.footer-note .container {
  border-top: #f5c52b 1px solid;
}
.footer-note .row ul {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 100%;
  list-style: none;
  padding: 10px 0;
}
.footer-note .row ul li {
  margin: 0 20px;
  position: relative;
  font-size: 13px;
}
.footer-note .row ul li:after {
  width: 1px;
  border-right: 1px solid #f5c52b;
  content: "";
  position: absolute;
  right: -20px;
  top: 6px;
  height: 10px;
}
.footer-note .row ul li a {
  padding: 0;
  color: #000;
}
.footer-note .row ul li:last-child:after {
  border: 0 none;
}
.footer-wrap .socialmedia li {
  margin: 0 15px 0 0;
}

/*Signup signin*/
.signup-section {
  background: url(/public/images/signup.jpg) no-repeat center center / cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.signup-box {
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 500px;
  padding: 75px 50px;
  text-align: center;
}
.signup-box .signup-img {
  margin: 0 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.signup-box .signup-img img {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #f4c018;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.signup-box h2 {
  color: #000;
  font-size: 32px;
  margin: 0 0 10px;
}
.sub-heading {
  font-size: 14px;
}
.signup-box ul {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}
.signup-box ul li {
  margin: 0 0 20px;
  text-align: left;
}
.signup-box ul li label {
  display: block;
}
.signup-box ul li input {
  padding: 8px 10px;
  border: 1px solid #837f7f;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  width: 100%;
  outline: 0;
  padding-right: 30px;
}

.inputField {
  position: relative;
}

.inputField > svg {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  color: grey;
  width: 20px;
}
.signup-box ul li input[type="submit"] {
  background: #f29924;
  color: #fff;
  border: 0 none;
}
.signup-box ul li.otp-box {
  display: flex;
  justify-content: space-between;
}
.signup-box ul li.otp-box input {
  width: 60px;
}
.text-right {
  text-align: right;
}
@media (min-width: 992px) {
  .navbar-nav {
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  .section-wrap {
    padding: 30px 0;
  }
  .section-circle .section-wrap:nth-child(2n),
  .section-circle .section-wrap {
    background-size: 200px auto;
  }
  .banner:before {
    display: none;
  }
  .mobile-center {
    display: flex;
    justify-content: center;
  }
  .mobile-top {
    margin-top: 30px;
  }
  ul.steps-wrap li .step {
    width: 50px;
    height: 50px;
    margin: 0 15px 0 0;
  }
  .step-btn {
    margin-left: 65px;
  }
  h2.right-lines:before {
    width: 100%;
    position: static;
  }
  h2.right-lines span {
    padding: 0 0 0 10px;
  }
  .mobile-text-right {
    text-align: right;
  }
  .mobile-right-section > div {
    order: 2;
  }
  .mobile-right-section > div:nth-child(2) {
    order: 1;
  }
  .mobile-bottom {
    margin-bottom: 30px;
  }
  ul.steps-row li {
    padding: 15px;
  }
  ul.steps-row {
    gap: 30px 0;
  }
  ul.steps-row li {
    width: 100%;
  }
  h2.center-lines:before,
  h2.center-lines:after {
    max-width: 80px;
  }
  .footer-wrap .col-md-3 {
    margin-bottom: 20px;
  }
  .footer-wrap .col-md-3:last-child {
    margin-bottom: 0;
  }
  .mobile-auto {
    margin: 0 auto;
  }
  .mobile-b10 {
    margin-bottom: 10px;
    /* margin-left: -3%; */
  }
  .banner .container {
    padding-top: 15px;
    padding-bottom: 15px;
    position: static;
    background: rgba(0, 0, 0, 0.1);
  }
  .banner ul li {
    flex-wrap: wrap;
  }
  .footer-note .row ul {
    flex-wrap: wrap;
  }
  .banner .carousel-indicators {
    bottom: 26px;
    left: auto;
    right: 20px;
  }
}
