.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.heading h2 {
  width: 50%;
}

.closebtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close {
  margin-left: auto;
  border: none;
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c8aace;
  color: white;
}

.modal {
  position: fixed;
  top: 15vh;
  left: 20%;
  width: 60%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.modal h2 {
  color: #4d1a4e;
  font-weight: 700;
  font-family: inherit;
}
.contactUs {
  width: 100%;
  padding: 1rem 2rem;
}

.inputField {
  width: 100%;
  margin-top: 1rem;
}

.inputField > label {
  color: #4d1a4e;
}
.inputField > label > span {
  color: red;
}

.inputField > input,
.inputField > textarea {
  width: 100%;
  padding: 10px 12px;
  border-radius: 10px;
  border: none;
  background: #f7f7f7;
  outline: none;
}

.submit {
  border: none;
  background: #4d1a4e;
  color: #ffff;
  padding: 10px 15px;
  margin-top: 1rem;
  float: right;
  border-radius: 10px;
  border: 2px solid #4d1a4e;
  font-weight: 600;
}

.submit:hover {
  background: #ffffff;
  color: #4d1a4e;
  border: 2px solid #4d1a4e;
  transition: 200ms ease-in-out;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .modal {
    width: 64%;
  }

  .submit {
    float: initial;
    margin-left: 20px;
    margin-right: auto;
  }
}
