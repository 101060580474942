.wrapper {
  background-color: #ececf0;
}

.userbox {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  height: auto;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.16%
  );
  box-shadow: 0 4px 4px #ccc;
  margin-bottom: 20%;
}

.innerbox {
  box-sizing: border-box;
  width: 100%;
  height: 67px;
  background: #c8aace;
  border: 1px solid #d9d9d9;
  padding: 19px;
  padding-left: 2%;
}

.innerbox p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
}

select.textfields::ms-expand {
  display: none;
}

select.textfields option {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

select.textfields {
  appearance: none;
  background: url(/public/images/down.svg) no-repeat right 10px center;
  background-size: 12px;
  padding-right: 20px;
  cursor: pointer;
}
.userdetail select.textfields {
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background: url(/public/images/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}

.singleuserwrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 25px;
  padding-top: 20px;
  margin-top: 0px;
}

.userselect {
  display: flex;
  column-gap: 1%;
  justify-content: center;
  font-weight: 700;
}

.required {
  color: red;
  margin-left: -1%;
  margin-bottom: 0px;
}

.textfields {
  box-sizing: border-box;
  width: 48%;
  height: 38px;
  left: 0px;
  top: 30.06px;
  background: #ffffff;
  border: 1.17681px solid #c1c1c1;
  border-radius: 10px;
  padding-left: 1%;
  left: 18.81px;
  top: 46.06px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18.8289px;
  line-height: 23px;
  /* text-transform: capitalize; */
  color: #c1c1c1;
}

.usermulti {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.selectwrapper {
  padding: 20px;
}
.selectinneruser {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.multibtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background: #ffc200;
  border-radius: 6.3981px;
  margin-left: 10px;
}

.multibtn button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10.237px;
  line-height: 12px;
  text-transform: capitalize;

  color: #000000;
}

@media only screen and (max-width: 768px) {
  .selectinneruser {
    width: 100%;
  }

  .userbox {
    width: 91%;
  }

  .innerbox {
    width: 100%;
  }

  .selectwrapper {
    flex-direction: column;
  }

  .userabt {

  }

  .inputstyle input {
    width: 100%;
  }

  .singleuserwrapper {
    margin-left: auto;
  }
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {
  .userbox {
    width: 93%
  }

  .innerbox{
    width: 100%;
  }

  .fields{
    width: 19%;
  }

  .req{
    margin-left: 75%;
  }
} */
