.blogpara {
  width: 149px;
  height: 68px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 68px;
  text-transform: capitalize;
  color: #540375;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}

.blogwrap {
  display: flex;
  width: 92%;
  margin-left: 7%;
  padding: 1%;
  min-height: 500px;
}

.blogin {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.listblog {
  width: 18%;
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #c8aace;
}

.cardouterblog {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 2rem;
  justify-content: center;
}

.blogcard {
  width: 27rem;
  height: 320px;
}

.bloglistingwrap {
  display: flex;
  justify-content: space-around;
  padding: 7px 10px 10px 20px;
  gap: 10px;
  width: 108%;
  height: 44px;
  background: #f4f8fc;
  border-radius: 5px;
  margin-bottom: 2%;
}

.minimize {
  width: 0%;
}

.paralist {
  margin-top: 2%;
}

.paralist > p {
  width: 330px;
  height: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #9496a2;
}

.blogdate {
  width: 23%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20.3044px;
  text-transform: capitalize;
  color: #6a6a6a;
}

.aipara {
  width: 80%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 52.4339px;
  text-transform: capitalize;
  color: #000000;
}

.blogimg {
  width: 97%;
}

.allparablog {
  width: 99%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.allparablogstrong {
  width: 99%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.blogcard .carddesc {
  text-transform: capitalize;
  color: #540375;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.blogwhole .description {
  padding-top: 2rem;
  width: 95%;
  text-align: justify;
}

.card-short-desc {
  color: grey;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .blogdate {
    width: 100%;
    font-size: 14px;
    margin-top: 1rem;
  }

  .aipara {
    font-size: 25px;
    line-height: 26px;
    width: 100%;
    margin-bottom: 5px !important;
  }
}

@media only screen and (max-width: 1180px) and (min-width: 768px) {
  .blogdate {
    width: 100%;
  }

  .aipara {
    font-size: 35px;
    line-height: 37px;
    width: 100%;
    margin-bottom: 5px !important;
  }
}

/* @media only screen and (max-width: 600px) {
    .rightwrapblog {
        display: none;
    }

    .blogcard {
        width: 20rem;
        height: 98%;
    }

    .listblog {
        width: 65%;
        font-size: 14px;

    }

    .blogwhole{
        width: 117%;
    }

    .blogdate{
        width: 100%;
        font-size: 14px;
    }

    .aipara{
        width: 100%;
        font-size: 18px;
        height: 2%;
    }

    .allparablog{
        width: 100%;
        font-size: 14px;
    }

    .blogpara{
        font-size: 29px;
        margin-left: 39%;
    }

    .carddesc{
        font-size: 14px;
    }

} */

/* Media for Ipads */

/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .rightwrapblog {
        display: none;
    }

    .blogcard {
        width: 20rem;
        height: 98%;
    }

    .listblog {
        width: 65%;
        font-size: 14px;

    }

    .blogwhole{
        width: 117%;
    }

    .blogdate{
        width: 100%;
        font-size: 14px;
    }

    .aipara{
        width: 100%;
        font-size: 18px;
        height: 2%;
    }

    .allparablog{
        width: 100%;
        font-size: 14px;
    }

    .blogpara{
        font-size: 29px;
        margin-left: 47%;
    }

    .carddesc{
        font-size: 14px;
    }

    .cardouterblog{
        width: 127%;
    }
} */
