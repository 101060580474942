.headerbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #c8aace;
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0px 0px;
  background: #fcfcfc;
}

.headerinner {
  width: 100%;
  height: 63px;
  left: 393px;
  top: 212px;
  background: #c8aace;
  border-radius: 7px 7px 0px 0px;
}

.headinnerwrap {
  box-sizing: border-box;
  width: 98%;
  margin-left: 1%;
  left: 0px;
  top: 56px;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.headcon {
  width: 100%;
  height: 56px;
  left: 0px;
  top: 0px;
  padding: 1%;
  background: #c8aace;
  border-radius: 8px 8px 0px 0px;
}

.headpara {
  width: 535.53px;
  height: 27px;
  left: 38.1px;
  top: 15px;
  margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  text-transform: capitalize;

  color: #ffffff;
}
.recheadinnerwrap > .reccon > button {
  font-size: 14px;
  padding: 7px 15px;
  font-weight: 700;
}
.outerboxhead {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.selfwrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 2%;
  align-items: center;
  padding: 80px 0;
}

.selfone {
  width: 43%;
  display: flex;
  justify-content: center;
  column-gap: 3%;
  height: 100%;
  padding: 15px;
}

.humanbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34%;
  height: 89%;
  margin-top: 3%;
}

.percenthuman {
  color: red;
  font-weight: bold;
  text-align: center;
}

.reporthuman {
  color: #c8aace;
  font-size: 12px;
}

.humanicon {
 font-size: 100px;
}

.humantwo {
  font-size: 150px;
}

.meterbox {
  width: 53%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5%;
  justify-content: right;
  align-items: center;
  margin-top: 1%;
  row-gap: 2rem;
}

.speebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 158px;
  border: 1px solid #c8aace;
  border-radius: 15px;
  padding: 15px;
}

.metericon {
  width: 40%;
  height: 40%;
}

.introspeed {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-transform: capitalize;
  color: #000000;
  text-align: center;
  margin:0px;
}

.speedpercent {
  width: 42px;
  height: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-transform: capitalize;
  color: #ccc;
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {
  .headerbox {
    width: 100%;
  }

  .headerinner {
    width: 100%;
  }

  .headinnerwrap {
    width: 96%;
    margin-left: 2%;
    height: auto;
    height: 114vh;
  }

  .headcon {
    width: 100%;
  }

  .selfone {
    width: 94%;
  }

  .meterbox {
    width: 100%;
    row-gap: 2rem;
  }
} 

@media screen and (min-width: 1180px) and (max-width: 1600px) {
  .outerboxhead {
    width: 100%;
  }

  .reccon {
    width: 100%;
  }
}
*/
@media screen and (min-width:1024px) and (max-width:1200px){

}
@media screen and (min-width:769px) and (max-width:1024px){
  .selfwrap{
    flex-direction: column;
    padding: 40px 0px;
  }
  .selfone, .meterbox{
    width: 100%;
  }
  .meterbox {
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .selfone{
    width: 100%;
    padding: 2%;
  }

  .meterbox{
    width: 100%;
  }

  .headinnerwrap{
    height: 230vh;
  }

  .headpara{
    font-size: 14px;
  }
}
