* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

#root {
  overflow: hidden;
}

body.dashboard-layout {
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  background: #fff;
  font-size: 16px;
}

.top-navbar {
  display: flex;
  position: fixed;
  z-index: 9;
  top: 0;
  flex-wrap: wrap;
  background-color: #f7f8fc;
  width: 100%;
}

.top-navbar .logo {
  width: 255px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
}

.top-navbar .logo a {
  display: block;
  padding: 10px;
}

.top-navbar .logo img {
  display: block;
  height: 50px;
  width: 100px;
}

.top-navbar .hamburger {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3%;
}

.top-navbar .hamburger a {
  display: flex;
  margin: 0 40px;
}

.top-navbar .admin-icons {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 5px 0;
  /*width: 20.5%;*/
}

.top-navbar .admin-icons ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.top-navbar .admin-icons ul li a {
  display: block;
  padding: 5px 10px;
}

.top-navbar .admin-icons ul li .notification-icon {
  position: relative;
}

.top-navbar .admin-icons ul li .notification-icon > span {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #3751ff;
  border: 3px solid #f7f8fc;
  z-index: 111;
  top: 3px;
  right: 7px;
}

.top-navbar .admin-icons .user-info {
  position: relative;
  width: 100%;
  border-left: 2px solid#DFE0EB;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 16px;
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  text-align: right;
  letter-spacing: 0.331818px;
  color: #252733;
  /* background: url("../public/images/three-dots.svg") no-repeat right 10px center; */
}

.menu {
  width: 30px;
  cursor: pointer;
}

.top-navbar .admin-icons .user-info .username {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 33px;
  text-align: right;
  color: #252733;
}

.top-navbar .admin-icons .user-info .avtar {
  width: 60px;
  height: 60px;
  margin-left: 20px;
}

.top-navbar .admin-icons .user-info .avtar img {
  width: 100%;
  height: auto;
  padding: 3px;
  border: 2px solid #dfe0eb;
  border-radius: 50%;
}

.wrapper {
  position: relative;
  display: flex;
  min-height: calc(100vh - 0px);
  padding-left: 88px;
  padding-top: 80px;
}

.wrapper.wrap-new {
  padding-left: 258px;
}

.left-bar {
  position: fixed;
  width: 258px;
  left: 0;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px #ddd;
}

/* .lefbarNone {
  display: none !important;
} */

.bar-name {
  display: initial;
}

.bar-change {
  width: 88px !important;
}

.left-bar ul {
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
}

.left-bar ul li a {
  /* border-left: 3px solid #fff; */
  padding: 18px 30px;
  display: block;
  color: #a4a6b3;
  text-decoration: none;
}

.left-bar ul li a svg {
  fill: #d9dae1;
  width: 14px;
}

.left-bar ul li a span {
  display: inline-block;
  margin-right: 25px;
}

.left-bar > ul > li > a:hover,
.left-bar > ul > li > a.active {
  border-left: 3px solid #540375;
  background: #c8aace;
  color: #fff;
}

.left-bar ul li a:hover svg,
.left-bar ul li a.active svg {
  fill: #fff;
  filter: invert(0%) brightness(118%) contrast(119%);
}

.middle-section {
  background: #ececf0;
  width: calc(100% - 258px);
  padding: 15px 0px;
}

.right-full-section {
  width: 100%;
  background: #ececf0;
}

.filterLibaryCont {
  border-bottom: 1px solid #b9b9b9;
  margin: 0 0 30px;
}

h1.pagetitle {
  font-weight: 700;
  font-size: 31px;
  line-height: normal;
  text-transform: capitalize;
  color: #540375;
  padding: 0 0 5px;
}

.dashboard-hr {
  border: 1px solid #b9b9b9;
  margin-top: -5px;
}

.libraryFilter select {
  border: none;
  padding: 10px;
  border-radius: 10px;
  min-width: 15rem;
  background: #c8aace;
  color: #ffff;
}

.libraryFilter > button {
  border: none;
  padding: 9px 25px;
  border-radius: 10px;
  background: #c8aace;
  color: #ffff;
  margin-right: 1rem;
}
.libraryFilter > button:hover {
  background: #d3b8d8;
  color: #ffff;
}

.paginationBtns > button {
  margin: 0 7px !important;
  background: #ffff;
  border: 1px solid #c8aace;
  color: #c8aace;
}

.paginationBtns > button:hover,
.paginationBtns > button:disabled,
.paginationBtns > button:active {
  background: #c8aace !important;
  border: 1px solid #c8aace !important;
}

.paginationBtns > button:disabled {
  cursor: not-allowed;
}

.top-ade {
  margin-bottom: 30px;
}

.top-ade img {
  width: 100%;
  height: auto;
}

.tab-platform .platform-box {
  background: #fff;
  box-shadow: 0px 3.06694px 7.66735px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.platform-box h2 {
  font-weight: normal;
  font-size: 16.8682px;
  line-height: 20px;
  text-transform: capitalize;
  color: #c8aace;
  padding: 0 0 5px;
}

.platform-box .platform-img {
  margin: 0 0 10px;
  position: relative;
  height: 272px;
  overflow: hidden;
}

.platform-box .platform-img img {
  width: 100%;
  height: auto;
}

.platform-box .platform-img span.title-name {
  position: absolute;
  left: -10px;
  background: #540375;
  padding: 5px 10px;
  color: #fff;
  font-size: 11px;
  top: 8px;
  /* height: 28px; */
}

a.btnfull {
  background: #c8aace;
  border-radius: 7px;
  display: flex;
  color: #fff;
  padding: 5px 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-decoration: none;
  font-size: 13px;
  height: 35.49px;
}

a.btnfull.yellow-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3cd5e;
  border-radius: 19.2608px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13.433px;
  line-height: 16px;
  /* identical to box height, or 122% */
  text-align: center;
  height: 48.15px;
  color: #000000;
}

.right-bar {
  width: 280px;
  background: #f7f8fc;
  padding: 15px;
}

.right-bar-section {
  margin-bottom: 30px;
}

.shadow-box {
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px;
}

.top-search {
  background: #ffff;
  border-radius: 10px;
  padding: 28px 20px;
  margin: 0 0 30px;
  width: 100%;
}

.top-search form {
  max-width: 100%;
  margin: 0 auto;
}

.top-search .form-row {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.top-search .form-row .input-box {
  width: 100%;
  padding: 0;
}

.top-search .form-row .to {
  background: #c8aace;
  color: #fff;
  line-height: normal;
  padding: 8px 15px;
  border-radius: 5px;
}

.top-search .form-row select,
.top-search .form-row input {
  background: #c8aace;
  width: auto;
  min-width: 100%;
  color: #fff;
  line-height: normal;
  padding: 8px 15px;
  border: 0 none;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.top-search .form-row input[type="date"] {
  text-transform: uppercase;
}

.top-search .form-row input[type="date"]::-webkit-calendar-picker-indicator {
  color: white;
  opacity: 1;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.top-search .submit-btn {
  background: #c8aace;
  color: #fff;
  line-height: normal;
  padding: 8px 15px;
  border: 0 none;
  border-radius: 10px;
}

.top-search .submit-refresh {
  background-image: url(/public/images/rotation.png);
  background-position: center center;
  background-size: 21px auto;
  background-repeat: no-repeat;
  min-width: 40px;
  border-radius: 10px;
  border: none;
  background-color: #c8aace;
}

ul.tabs {
  margin: 0 0 30px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 1rem;
}

ul.tabs li {
  margin: 0 30px 0 0;
}

ul.tabs li span {
  padding: 9.62819px 25px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #4d1a4e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9.62819px 38px;
  gap: 3.67px;
  background: #ffffff;
  box-shadow: 0px 2.40705px 6.01762px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

ul.tabs li span.active,
ul.tabs li span:hover {
  background-color: #4d1a4e;
  color: #fff;
}

ul.tabs li:last-child {
  margin: 0 0 0 auto;
}

ul.tabs li:last-child span {
  border-radius: 0;
}

.assessment-box {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 40px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 30px;
}

.assessment-box > * {
  width: 100%;
  margin: 0 0 8px;
}

.assessment-box h2 {
  color: #540375;
  font-weight: 500;
  font-size: 22px;
  line-height: normal;
  margin-bottom: 12px;
}

.assessment-box .date-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assessment-box .date-box .date {
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: #7e8299;
}

.assessment-box .date-box .mike-box {
  margin-left: auto;
}

.assessment-box .date-box .mike-box img {
  height: 26.91666603088379px;
  width: 19.83333396911621px;
  border-radius: 0px;
}

.assessment-box .purpose {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 13px;
  margin-bottom: 15px;
}

.version-count-wrap {
  display: flex;
  justify-content: flex-start;
}

.version-count-wrap > div {
  width: 33.33%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16.4836px;
  line-height: 27px;
  color: #000000;
}

.version-count-wrap span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22.4836px;
  line-height: 27px;
  display: block;
  color: #540375;
}

a.btn-m {
  background-color: #4d1a4e;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 10px 38px 10px 38px;
  box-shadow: 0px 2.40705px 6.01762px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

a.btn-m.yellow-btn {
  background: #f3cc4d;
}

.no-completed-assessment {
  color: #4d1a4e;
  font-weight: 500;
  font-size: 22.4836px;
}

.form-detail h2 {
  margin: 0 0 25px;
  color: #540375;
  font-size: 27.4836px;
}

.gender-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.gender-box .gender {
  margin: 0 18px 0 0;
}

.gender-box .gender input {
  display: none;
}

.gender-box .gender span {
  background: #f4f5f9;
  border: 1px solid #c9ccd3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  padding: 10px;
  color: #c5c8cf;
  font-weight: bold;
  align-items: center;
  width: 234px;
  font-size: 20px;
}

.gender-box .gender span img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #c9ccd3;
  background: #fff;
  margin-right: 22px;
}

.gender-box .gender input:checked + span,
.gender-box .gender input:checked + span img {
  border-color: #c8aace;
  color: #c8aace;
  height: 100%;
}

.assessment-btn {
  margin-bottom: 12px;
  text-align: right;
}

.assessment-btn a {
  border-radius: 0;
  margin-top: 10px;
}

.form-detail .form-label {
  font-size: 18px;
  font-weight: 700;
  color: #979797;
}

.form-detail .form-control {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 20px;
  color: #808080;
  font-size: 18px;
  height: 42px;
  padding: 0 20px;
  font-weight: 400;
}

.form-detail .form-control::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  text-transform: capitalize;

  color: rgba(128, 128, 128, 0.5);
}

.form-detail select.form-control {
  background: #fff url("/public/images/selectarrow.svg") no-repeat right 20px
    center;
}

.profile-form-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 48px;
}

.profile-form-row > * {
  width: calc(50% - 12px);
  display: flex;
  gap: 12px;
  align-items: center;
}

.profile-form-row .form-label {
  min-width: 170px;
  max-width: 170px;
}

.profile-form-column {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 40px;
}

.profile-form-column > * {
  width: 70%;
}

.profile-form-column > div {
  position: relative;
}

.profile-form-column > div > svg {
  position: absolute;
  right: 2%;
  height: 22px;
  width: 22px;
  color: grey;
}

.profile-form-column > div > .invalid-msg {
  position: absolute;
  top: 40px;
  left: 200px;
}

.profile-form-submit {
  display: flex;
  justify-content: center;
  max-width: 70%;
}

.profile-form-submit button {
  background: #4d1a4e;
  box-shadow: 0px 2.40705px 6.01762px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 0 1rem;
  border: none;
  height: 42px;
  font-weight: 700;
  font-size: 16px;
  width: 150px;
}

.profile-dropdown {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
  top: 99.99%;
  right: -300px;
  position: absolute;
  width: 240px;
  background: #fff;
  box-shadow: 0px 5.45665px 13.6416px rgba(0, 0, 0, 0.1);
}

.show-box .profile-dropdown {
  opacity: 1;
  visibility: visible;
  right: 0;
}

.profile-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.profile-dropdown ul li {
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #d9d9d9;
}

.top-navbar .profile-dropdown ul li a {
  color: #898989;
  font-size: 16px;
  padding: 15px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
}

.profile-dropdown ul li a svg {
  fill: #898989;
  margin: 0 10px 0 0;
}

.top-navbar .profile-dropdown ul li a:hover {
  background: #f3cd5e;
  color: #fff;
}

.profile-dropdown ul li a:hover svg {
  fill: #fff;
}

.overlay {
  display: none;
  background: rgba(98, 94, 94, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.form-popup {
  display: none;
  position: fixed;
  z-index: 1000;
  width: 800px;
  margin: 0 -400px;
  top: 50px;
  left: 50%;
  background-color: #fff;
  border-radius: 10px;
  padding: 35px 35px 50px;
}

.overlay.show-popup,
.form-popup.show-popup {
  display: block;
}

.form-popup h2 {
  font-size: 31px;
  line-height: normal;
  margin: 0 0 30px;
  color: #540375;
}

.form-popup .closeBtn {
  background: #c8aace;
  margin-bottom: 2rem;
  border-radius: 50%;
  cursor: pointer;
}

.form-popup .closeBtn svg {
  width: 30px;
  height: 30px;
  color: white;
  padding: 5px;
}

.form-popup label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4d1a4e;
  text-transform: capitalize;
}

.form-popup label span {
  color: #ff0000;
}

.form-popup input,
.form-popup select {
  background: #c8aace;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  border: 0 none;
  color: #fff;
  font-size: 16px;
  line-height: normal;
}

.form-popup select::ms-expand {
  display: none;
}

.form-popup select {
  appearance: none;
  background: #c8aace url(/public/images/icons/down-ward-arrow.svg) no-repeat
    right 10px center;
  background-size: 12px;
  padding-right: 20px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
  line-height: normal;
}

.form-popup input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background: url(/public/images/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}

.custom-grid .col-md-6 {
  margin-bottom: 35px;
}

.form-popup .submit-btn {
  width: 7rem;
  margin: 0 auto;
}

.form-popup .submit-btn input {
  width: 111px;
  height: 41.92px;
  background: #4d1a4e;
  box-shadow: 0px 2.45px 6px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
}

.custom-grid .col-md-6:nth-child(2n) {
  padding-left: 30px;
}

.custom-grid .col-md-6:nth-child(2n + 1) {
  padding-right: 30px;
}

.form-popup .submit-btn {
  text-align: center;
}

.form-popup input[type="button"] {
  padding: 10px 38px;
  width: auto;
  background: #4d1a4e;
  box-shadow: 0px 2.40705px 6.01762px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.accordian-bar {
  background: #c8aace;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.accordian-bar:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #fff;
}

.accordian-bar-active:after {
  transform: rotate(90deg);
}

.accordian-box {
  background: #fff;
  margin-bottom: 15px;
  box-shadow: 0px 3.06694px 7.66735px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.accordian-bar h2 {
  font-size: 22px;
  color: #fff;
  margin: 0;
}

.accBtns {
  margin: 30px 60px 0 60px;
}

.withQa {
  margin-bottom: 40px;
}

.acc-arrows {
  background: #d9d9d9;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.acc-arrows svg {
  width: 15px;
}

.acc-arrows:hover {
  background: #4d1a4e;
}

.visHidden {
  visibility: hidden;
  opacity: 0;
}

.accordian-content h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 22px;
}

.accordian-content img {
  margin: 0 auto 22px auto;
  display: block;
}

.accordian-content ul {
  padding: 0 0 0 15px;
  margin-bottom: 25px;
}

.accordian-content p {
  margin-bottom: 25px;
}

.accordian-content p:last-of-type {
  margin-bottom: 0;
}

.accordian-content audio {
  display: block;
  margin: 0 auto 15px auto;
}

.question-answers {
  background: #fff;
  padding: 27px 80px 0 80px;
  /* border-top: 30px solid #d9d9d9; */
  margin: -20px -20px 0 -20px;
  padding-bottom: 50px;
}

.question-answers h3 {
  font-weight: 500;
  font-size: 28px;
  color: #717070;
  line-height: 1.5;
  margin-bottom: 20px;
}

.question-answers h4 {
  font-size: 28px;
  color: #000000;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 20px;
}

.question-answers textarea {
  border: 1px solid #717070;
  border-radius: 30px;
  width: 100%;
  height: 186px;
  resize: none;
  padding: 20px;
}

.assesments-container {
  padding: 15px;
  border-bottom: 1px solid #b9b9b9;
  display: flex;
  gap: 56px;
}

.assesment-component {
  background: #ffffff;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.assesment-content {
  border-left: 1px solid #385987;
  padding-left: 10px;
}

.assesment-content span {
  display: block;
  color: #385987;
  max-width: 120px;
}

.number-of-assesment {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.type-of-assesment {
  font-size: 18px;
}

.assesment-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #95b7e7;
  min-width: 61px;
  height: 61px;
  border-radius: 50%;
}

.assesment-component-yellow .assesment-content,
.assesment-component-yellow .assesment-image {
  border-color: #ffd85f;
}

.assesment-component-green .assesment-content,
.assesment-component-green .assesment-image {
  border-color: #ade0d9;
}

.assesment-component-red .assesment-content,
.assesment-component-red .assesment-image {
  border-color: #fd9085;
}

.assesment-component-yellow .assesment-content span {
  color: #ffd85f;
}

.assesment-component-green .assesment-content span {
  color: #ade0d9;
}

.assesment-component-red .assesment-content span {
  color: #fd9085;
}

.assesment-image img {
  max-width: 30px;
}

.score-tab > .teacherinnerbox {
  display: flex;
  justify-content: space-between;
}

.score-tab .input {
  position: relative;
  margin-right: 2rem;
}

.score-tab .input > input,
.score-tab .input > select {
  width: 357px;
  height: 36px;
  left: 0px;
  top: 0px;
  outline: none;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  padding-left: 2rem;
  padding-right: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #956a9d;
  text-transform: capitalize;
}

.score-tab .input > img {
  position: absolute;
  left: 10px;
  top: 10px;
}

.score-tab .input > input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #956a9d;
  text-transform: capitalize;
}

/* @media screen and (min-width: 1024px) and (max-width: 1600px) {

  .top-search .form-row {
    justify-content: center;
    flex-wrap: wrap;
  }

  .top-search .form-row .input-box,
  .top-search .form-row .to,
  .top-search .form-row .submit-btn,
  .top-search .form-row .submit-refresh {
    width: 33%;
    margin: 0 0.7rem;
  }

  a.btn-m {
    padding: 5px;
  }

  .score-tab {
    width: 100%;
  }

  .score-tab .teacherinnerbox {
    width: 100% !important;
  }

  .score-tab .tableContainer {
    width: 100%;
    overflow: auto;
  }

  .score-tab .table {
    width: 800px;
    overflow-x: scroll;
  }

  .score-tab .input > input,
  .score-tab .input > select {
    width: 100%;
  }

  .score-tab .teacherinnerbox > p {
    width: 10rem;
    margin: 0;
  }
  .profile-form-row {
    display: block;
  }

  .profile-form-row > * {
    width: 100%;
    max-width: 35rem;
    margin-top: 1rem;
  }
} */

/*Expection only for input field */
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .profile-form-row > * {
    width: calc(100% - 12px);
  }
}

/*Expection only for input field */

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .profile-form-row > * {
    width: calc(100% - 12px);
  }
  .assesments-container {
    flex-wrap: wrap;
  }
  .assesment-component {
    width: 29%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .lefbarNone {
    display: none !important;
  }

  .wrapper.wrap-new {
    padding-left: 0px;
  }

  .wrapper {
    padding-left: 0px;
  }

  .left-bar {
    z-index: 2;
  }

  .top-navbar {
    z-index: 9;
  }

  .right-bar {
    width: 250px;
  }

  .middle-section {
    width: calc(100% - 250px);
  }

  .middle-section {
    width: calc(100% - 258px);
  }

  ul.tabs li {
    margin: 0 10px 0 0;
  }

  .profile-form-row > * {
    width: calc(100% - 12px);
  }
  .left-bar {
    width: 0px;
  }
  .bar-change {
    width: 258px !important;
  }
  .left-bar .nav_text,
  .left-bar .bar-none {
    opacity: 0 !important;
    transition: all 1s;
  }
  .left-bar.bar-change .nav_text,
  .left-bar.bar-change .bar-none {
    opacity: 1 !important;
  }
  .assesments-container {
    flex-wrap: wrap;
  }
  .assesment-component {
    width: 46%;
  }
}

@media only screen and (max-width: 768px) {
  .form-popup h2 {
    font-size: 22px;
  }

  .libraryFilter > button {
    margin-bottom: 2%;
  }

  .libraryFilter select {
    min-width: 9rem;
    margin-bottom: 2%;
  }

  .profile-form-column > div > .invalid-msg {
    top: 95%;
    left: 4px;
    width: 100%;
  }

  .profile-form-submit button {
    height: 51px;
  }
  .top-navbar .admin-icons .user-info .avtar img {
    left: -10px;
    position: relative;
  }

  .form-popup {
    width: 91% !important;
    margin: 0 -174px !important;
  }

  .custom-grid .col-md-6:nth-child(2n + 1) {
    padding-right: 0 !important;
  }

  .custom-grid .col-md-6:nth-child(2n) {
    padding-left: 13px !important;
  }

  .profile-form-submit {
    margin-top: 5%;
    max-width: 0% !important;
    margin-left: auto;
    margin-right: auto;
  }

  /* .profile-form-row {
    display: initial;
  } */

  .profile-form-column > div {
    width: 97%;
  }

  .profile-form-column > div > svg {
    top: 43px;
  }

  .profile-form-row > * {
    display: initial;
    width: 100%;
  }

  .tableContainer {
    width: 261px;
    overflow: auto;
  }

  ul.tabs li:last-child {
    margin: 0px !important;
  }

  .react-calendar__navigation button {
    margin-left: -7% !important;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .middle-section {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .right-bar {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .shadow-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  a.btnfull {
    width: 95%;
  }

  .top-navbar {
    height: 5rem;
    flex-wrap: nowrap;
    padding: 0px 10px;
  }

  .admin-icons > ul,
  .admin-icons .username,
  .logo {
    display: none !important;
  }

  .admin-icons .user-info {
    border: none !important;
    padding: 0 !important;
  }

  .admin-icons .avtar {
    margin-right: 2rem;
    margin-top: 10px;
  }

  .wrapper {
    padding: 0 !important;
    padding-top: 80px !important;
  }

  .left-bar {
    z-index: 1 !important;
  }

  .lefbarNone {
    display: none !important;
  }

  /* Search filter */
  .top-search .form-row {
    justify-content: center;
    flex-wrap: wrap;
  }

  .top-search .form-row .input-box,
  .top-search .form-row .to,
  .top-search .form-row .submit-btn,
  .top-search .form-row .submit-refresh {
    width: 50%;
    margin: 0 0.7rem;
  }

  .right-full-section {
    height: auto;
  }

  .assesments-container {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
  }
}

/* @media screen and (min-width: 1024px) and (max-width: 1600px) {
  .assesments-container {
    flex-wrap: wrap;
    display: flex;
  }

  .assesment-component {
    width: 46%;
  }
} */
