.videowrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 498px;
  background: #fcfcfc;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #c8aace;
}

.analysisinner {
  width: 100%;
  height: 63px;
  background: #c8aace;
  border-radius: 7px 7px 0px 0px;
}

.videocontent {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 2%;
}

.ladyimg {
  width: 48%;
  /* height: 300px; */
}

.ladyimg > div > video {
  overflow: hidden;
  border-radius: 21px;
}

.ladyimg img {
  width: 83%;
  height: 123%;
  border-radius: 25px;
}

.ladyimg video {
  /* height: 100%; */
  width: 100%;
}

.videotab {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.create-video-container > .videotab > .active {
  background: #ffc200 !important;
}

.create-video-container > .videotab > .contenttab {
  border-top-left-radius: 25px;
}

.create-video-container > .videotab > .insighttab {
  border-top-right-radius: 25px;
}

.tabcontent {
  width: 48%;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 25px;
}

.contenttab {
  border-top-left-radius: 0px 0px 20px 20px;
  border-bottom-left-radius: 0px 0px 20px 20px;
}

.insighttab {
  border-top-right-radius: 0px 0px 20px 20px;
  border-bottom-right-radius: 0px 0px 20px 20px;
}

.create-video-container > .videotab > .tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 5px;
  width: 51%;
  height: 54px;
  border: none;
  background: #e3e3e3;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0em;
}

.create-video-container {
  display: flex;
  justify-content: space-between;
}

.playimg {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffc200;
}

.contentdesc {
  padding-left: 1rem;
  display: flex;
  column-gap: 10px;
  margin-top: 1rem;
}

.firstDesc {
  flex-direction: column;
}

.firstDesc > .movements {
  padding-left: 2rem;
}

.firstDesc > .movements ul li {
  min-width: 200px;
  display: flex;
  column-gap: 10px;
  margin-bottom: 10px;
}

.firstDesc > .movements button {
  border: none;
  background: #ffc200;
  border: 1px solid #ffc200;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstDesc > .movements button:hover {
  transition: 200ms ease-in-out;
  background: #ffdb63;
  border: 1px solid #ffc200;
}
.firstDesc > .movements > ul {
  list-style: none;
}

.contentdesc > p {
  font-weight: 700;
}

.contentdesc > .heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
}

.contentdesc .heading p {
  display: flex;
  row-gap: 7px;
  column-gap: 3%;
  margin-left: 3%;
  margin-top: 3%;
  font-weight: 700;
  margin: 0 0 0 10px;
}
/* 
@media screen and (min-width: 1180px) and (max-width: 1600px) {
  .videowrap {
    width: 100%;
    height: auto;
  }

  .analysisinner {
    width: 100%;
  }

  .videocontent {
    flex-wrap: wrap;
  }

  .ladyimg {
    margin-left: 15%;
  }

  .tabcontent {
    width: 58%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
} */

@media only screen and (max-width: 768px) {
  .videowrap {
    height: auto;
  }

  .videocontent {
    flex-wrap: wrap;
  }

  .ladyimg {
    width: 96%;
  }

  .tabcontent {
    width: 96%;
    margin-top: 2%;
  }
}
