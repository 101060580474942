.videowrapper{
    display: flex;
    flex-direction: column;
    height: 680px;
    margin-bottom: 13%;
}

.innervideowrapper{
    width: 1214px;
    height: auto;
    background: #C8AACE;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    padding-left: 2%;
    padding-top: 1%;
}

.innervideowrapper p {
    width: 1214px;
    height: 33px;
    left: 59px;
    top: 13px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 27.6203px;
    line-height: 33px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.outercontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.videostore{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 51vh;
    margin-bottom: 2%;
    row-gap: 2%;
    background-color:#ccc ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recordbtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    column-gap: 4%;
    margin-top: -6%;
    /* margin-top: 20%; */
    /* margin-bottom: 10%; */
}

.recordstop{
    margin-top: -2%;
}

.videopreview{
    display: flex;
    flex-direction: column;
    row-gap: 100px;
}

.recordimg{
    width: 6%;
}

.timeformat{
    margin-left: 45%;
    margin-right: auto;
    color: red;
}

@media screen and (min-width: 1180px) and (max-width: 1600px) {

.videowrapper{
    width: 95%;
}
.innervideowrap{
    width: 100%;
}

.videocard{
    flex-wrap: wrap;
}

.recordwrap{
    margin-bottom: 2%;
}

.innervideowrapper{
    width: 100%;
}
}


@media only screen and (max-width: 768px) {
    .innervideowrapper{
        width: 100%;
    }

    /* .videowrapper{
        height: auto;
    } */
}