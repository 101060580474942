.findingwrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  width: 995;
  height: auto;
  padding-bottom: 3rem;
  background: #fcfcfc;
  border-radius: 10px 10px 0px 0px;
}

.findinginner {
  width: 100%;
  height: 63px;
  left: 393px;
  top: 212px;
  background: #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.outsidefre {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.freqwrap {
  display: flex;
  column-gap: 2%;
}

.freqhead {
  box-sizing: border-box;
  width: 40rem;
  height: 446px;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.freqinner {
  width: 100%;
  height: 56px;
  left: 0px;
  top: 0px;
  margin-top: -2.4%;
  background: #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.freqpara {
  width: 316.95px;
  height: 27.07px;
  padding: 2%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
}

.innerbodyfreq {
  width: 58%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
}

.circular-graph {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -3rem;
}

.circular-graph > svg {
  width: 70%;
  height: 70%;
}

.circular-graph p {
  position: absolute;
  top: 50%;
  left: 50%;
}

.langfre {
  color: #a5a54e;
  font-size: 31px;
  margin-bottom: 0rem;
}

.videofre {
  color: #ccc;
  font-size: 31px;
}

.littlefre {
  color: orange;
  font-size: 31px;
}

.tellfre {
  color: blue;
  font-size: 31px;
}

.cafefre {
  color: red;
  font-size: 31px;
}

.lordfre {
  color: #ccc;
  font-size: 31px;
}

.champfre {
  color: green;
  font-size: 31px;
}

.lanvide {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  line-height: 20px;
}

.sentimentwrap {
  box-sizing: border-box;
  width: 91%;
  height: 445.56px;
  left: 0.5px;
  top: 55.5px;
  background: #ffffff;
  border: 1px solid #c8aace;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px 10px 0px 0px;
}

.sentiinner {
  width: 100%;
  padding: 1%;
  height: 56px;
  background: #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.sentipara {
  width: 596.91px;
  height: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26.6372px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
}

.chartwrap {
  margin-top: 2%;
}

@media screen and (min-width: 1180px) and (max-width: 1600px) {
  .outsidefre {
    flex-wrap: wrap;
  }

  .freqwrap {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
  }

  .sentimentwrap {
    width: 84%;
  }

  .sentiinner {
    width: 100%;
  }

  .findingwrap {
    height: auto;
  }
}
