.userwrapper_padd {
  padding: 15px;
  width: 100%;
}
.userwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #c8aace;
}

.userinnercon {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  width: 100%;
  height: 68px;
  background: #c8aace;
  border-radius: 8px 8px 0px 0px;
}

.userusertablee {
  margin-top: 3%;
  width: 90%;
  height: 100vh;
}

.usertable {
  overflow-y: auto;
  width: 100%;
  padding: 15px;
}

.usertable td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15.72px;
  line-height: 19px;
  text-transform: lowercase;
  color: #000000;
}

.usertable::-webkit-scrollbar {
  width: 12px;
}

.usertable::-webkit-scrollbar-track {
  box-sizing: border-box;

  position: absolute;
  width: 14px;
  height: 321.96px;
  left: 537px;
  top: 0px;

  border: 1px solid #c8aace;
}

.usertable::-webkit-scrollbar-thumb {
  width: 14px;
  background: #ffc200;
}

.userpara {
  margin-left: 2%;
  margin-top: 0.3%;
  width: 155px;
  height: 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
}

.userpara p {
  width: 141px;
  height: 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  text-transform: capitalize;

  color: #ffffff;
}

.usericonpara {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13.0259px 21.5705px;
  justify-content: center;
  gap: 4.34px;
  width: 138%;
  height: 43.05px;
  left: 1224.5px;
  top: 8px;
  margin-left: -31px;
  background: #ffffff;
  border-radius: 8.68391px;
  color: #540375;
  font-weight: 500;
  margin-top: -5%;
}

.usericonpara p {
  width: 104px;
  height: 17px;
  margin-top: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.8942px;
  line-height: 17px;
  text-transform: capitalize;
  color: #540375;
}

.rowstyle {
  height: 58px;
}

.switch input {
  margin-left: 2% !important;
  width: 35% !important;
  height: 2.5vh;
}

.edituser img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px;
  gap: 5.64px;
  width: 50px;
  height: 48px;
  background: #ffc200 !important;
  border-radius: 11.2723px;
  margin-top: 3%;
}

@media only screen and (max-width: 768px) {
  .userwrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .userpara {
    padding: 5%;
  }

  .userpara p {
    font-size: 21px;
  }

  .usericonpara {
    font-size: 14px;
    margin-top: 8%;
  }
  .switch input {
    width: 77% !important;
  }
}
