.qnbankouterbox_sec {
  padding: 15px;
  width: 100%;
}
.qnbankouterbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c8aace;
}

.qnbankinner {
  width: 100%;
  height: 68px;
  padding: 15px;
  background: #c8aace;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.qnparagraph {
  width: 160px;
  height: 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  margin-left: 2%;
  margin-top: 8%;
}

.qnicon {
  display: flex;
  justify-content: space-evenly;
}
/* 
.qncard {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  padding: 15px 84px 15px 90px;
  justify-content: space-between;
}
.qncard::after {
  content: "";
  width: 100%;
 
}  */


.qncard{
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 6px;
  column-gap: 8rem;
} 

.qnwholecard{
  width: 345px;
  margin-top: 2rem;
}

.deleteicon {
  margin-left: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #ef4b5c;
  line-height: 40px;
}

.deleteicon:hover {
  background-color: #d80016;
  cursor: pointer;
}

.qnpara {
  width: 300px;
  height: 48px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #555555;
}

p.qnpara {
  width: 100%;
}

.qniconpara {
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 500;
}

.qniconpara p {
  width: 104px;
  height: 17px;
  margin-top: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.8942px;
  line-height: 17px;
  text-transform: capitalize;
  color: #540375;
}

.filtericon {
  display: flex;
  padding: 10px;
  margin-right: 23px;
  height: 5vh;
  width: 44px;
  margin-top: -9px;
  background-color: #fff;
  border-radius: 12px;
}

.filtericon img {
  width: 29px;
  height: 26px;
}

.level {
  position: absolute;
  color: black;
  margin-top: -306px;
  width: 7%;
  margin-left: -0.5%;
  border-radius: 5px;
}

.level p {
  width: 81px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 11px;
  margin-left: 10px;
}
.css-wsflr3-MuiPaper-root-MuiCard-root {
  width: calc(25% - 30px);
  max-width: initial !important;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .css-wsflr3-MuiPaper-root-MuiCard-root {
    width: calc(33% - 30px);
    max-width: initial !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .css-wsflr3-MuiPaper-root-MuiCard-root {
    width: calc(50% - 30px);
    max-width: initial !important;
  }
  .qniconpara {
    width: 163px;
  }
}
@media only screen and (max-width: 768px) {
  .qnwholecard{
    width: 312px;
  }
  .qncard{
    display: inline;
  }

  .css-wsflr3-MuiPaper-root-MuiCard-root {
    width: 98% !important;
  }

  .qnbankouterbox {
    margin-left: auto;
    margin-right: auto;
  }

  .qnparagraph {
    font-size: 22px;
  }

  .qniconpara {
    font-size: 14px;
  }

  .level {
    margin-top: -311px;
    width: 121px;
    height: 40px;
    margin-left: -3.5%;
  }

  .deleteicon {
    width: 39px;
    height: 42px;
  }
}

/* @media screen and (min-width: 1180px) and (max-width: 1600px) {

  .qncard {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0%;
    width: 93%;
  }

  .level {
    margin-top: -314px;
    width: 8%;
    height: 5%;
    margin-left: -0.5%;
  }

  .deleteicon {
    height: 4vh;
    width: 13%;
    padding-left: 4%;
    padding-top: 2%;
  }

  .qnicon {
    width: 16%;
  }
} */
