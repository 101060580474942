.teacherboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  margin-top: 40px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #c8aace;
  margin: 0px;
  margin-bottom: 40px;
}

.noreport {
  width: 100%;
  margin-left: 174%;
  margin-top: 45%;
}
.noreporttwo {
  width: 100%;
  margin-left: 215%;
  margin-top: 24%;
}

.teacherinnerbox {
  width: 100%;
  height: 68px;
  background: #c8aace;
  border-radius: 8px 8px 0px 0px;
}
.teacherinnerbox_padd {
  padding: 20px;
  width: 100%;
}
.scoreboard {
  width: 100%;
  box-sizing: border-box;
  left: 139.25px;
  top: 596px;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.scoreinnerbox {
  width: 100%;
  height: 56px;
  background: #c8aace;
  border-radius: 8px 8px 0px 0px;
}

.scorepara {
  width: 162px;
  height: 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin-top: -42px;
  text-transform: capitalize;
  color: #ffffff;
  margin-left: 25px;
  margin-top: 0px;
  padding-top: 10px;
}
.teacherboard_sec {
  padding: 15px;
}
.scorebox {
  display: flex;
  column-gap: 80px;
  padding: 15px;
}

.avgscore {
  box-sizing: border-box;
  width: 51%;
  left: 139.25px;
  top: 596px;
  background: #ffffff;
  border: 1px solid #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.abginnerbox {
  width: 100%;
  height: 56px;
  background: #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.avgpara {
  width: 400px;
  height: 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.chartbox {
  display: flex;
  column-gap: 2%;
  margin-top: 40px;
  width: 77.2%;
}

.allscoreouter {
  box-sizing: border-box;
  margin-bottom: 5%;
  width: 100%;
  margin-top: 40px;
  border: 1px solid #c8aace;
  border-radius: 10px 10px 0px 0px;
}

.allscoreinner {
  display: flex;

  width: 100%;
  height: 69px;

  background: #c8aace;
  border-radius: 8px 8px 0px 0px;
  justify-content: space-evenly;
  /* padding-top: 12px;
    padding-left: 10px; */
  align-items: center;
}

.allscorepara {
  margin-top: 9px;
  left: 33px;
  top: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
}

.tabl {
  overflow-y: auto;
  height: 300px;
  width: 50%;
  margin-top: 5%;
  padding: 10px;
}

.tabls {
  overflow-y: scroll;
  height: 300px;
  width: 100%;
  padding: 15px;
}

.tabl::-webkit-scrollbar {
  width: 12px;
}

.tabl::-webkit-scrollbar-track {
  box-sizing: border-box;

  position: absolute;
  width: 14px;
  height: 321.96px;
  left: 537px;
  top: 0px;

  border: 1px solid #c8aace;
}

.tabl::-webkit-scrollbar-thumb {
  width: 14px;
  background: #c8aace;
}

.eye img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px;
  gap: 5.64px;
  width: 50px;
  height: 48px;
  background: #c8aace !important;
  border-radius: 11.2723px;
  margin-top: 3%;
}

.searchicon {
  position: absolute;
  margin-left: -27%;
  margin-top: 0.4%;
}

#cars {
  border: none;
  outline: none;
  width: 33%;
  height: 70%;
  background: #ffffff;
  border-radius: 10px;
  left: 28px;
  top: 13.95px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #956a9d;
  padding-left: 20px;
  font-size: 16px;
}

.values {
  width: 149.5px;
  height: 19.11px;
  left: 28px;
  top: 13.95px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #956a9d;
  background: #ffffff;
}

.refreshcontainer {
  width: 5%;
  height: 70%;
  left: 958.5px;
  top: 15px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refreshicon {
  width: 22.5px;
  height: 17.99px;
  left: 975.5px;
  top: 26.06px;
}

.piechart {
  width: 50%;
  /* height:50vh; */
  margin-top: 20px;
}

.pieinner {
  width: 100%;
  height: 450px;
}

@media only screen and (max-width: 768px) {
  .scoreboard {
    height: 100vh;
  }
  .piechart {
    height: 20rem;
  }

  .pieinner {
    height: 61vh;
    width: 100%;
  }

  .tabl {
    width: 100%;
    margin-bottom: 5%;
    height: auto;
  }

  .scorebox {
    flex-wrap: wrap;
  }

  .chartbox {
    flex-wrap: wrap;
  }

  .avgscore {
    width: 100%;
    margin-bottom: 5%;
  }

  .avgpara {
    width: 100%;
    font-size: 14px;
    justify-content: flex-start;
    margin-left: 1%;
  }

  .allscoreinner {
    height: 3rem;
    flex-direction: column;
  }

  .allscorepara {
    font-size: 14px;
    width: 100%;
    margin-left: 13%;
  }

  .inputbox {
    width: 70% !important;
    left: 0px !important;
  }

  .searchicon {
    margin-left: -34%;
    margin-top: -25.6%;
  }

  #cars {
    width: 70% !important;
    height: 4rem;
    margin-top: -3%;
    padding-left: 6px;
  }

  .refreshcontainer {
    width: 19%;
    height: 17%;
  }

  .teacherboard {
    height: auto;
  }

  .tabls {
    height: auto;
  }
}
/* 
@media screen and (min-width: 1180px) and (max-width: 1600px) {
  .scorebox {
    width: 100%;
    margin-left: 0px;
    padding: 2%;
     height: 21rem; 
     overflow: hidden; 
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;
  }

  .tabl {
    width: 100%;
    margin-top: -56%;
    margin-bottom: 5%; 
  }

  .pieinner {
    margin-bottom: -70px; 
  }

  .avgpara {
    padding-left: 3%;
    justify-content: flex-start;
    font-size: 18px;
    width: 100%;
  }

  .allscorepara {
    width: 95%;
    font-size: 18px;
    text-align: left;
  }

  .inputbox {
    width: 23%;
  }

  .searchicon {
    margin-left: -21%;
  }

  #cars {
    height: 61%;
    margin-top: 1%;
    padding: 0px;
    margin-left: 1%;
  }

  .refreshcontainer {
    height: 63%;
  }
} */
.middle-section-tech {
  width: 100%;
  padding: 15px;
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .scorebox {
    flex-direction: column;
  }
  .tabl,
  .piechart {
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .scorebox {
    flex-direction: column;
  }
  .tabl,
  .piechart {
    width: 100%;
  }
}
