.myVideoSwiper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.myVideoSwiper .swiper-slide video {
  height: 100%;
  width: 100%;
}
