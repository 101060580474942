.wrappercourse {
  position: relative;
  padding-left: 255px;
  display: flex;
  min-height: calc(100vh - 86px);
  background-color: #fff;
}

.coursetab {
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  row-gap: 10px;
}

.create-course-container > .coursetab > .active {
  background: #dedada !important;
}

.coursewrap {
  width: 86%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  height: auto;
  margin-bottom: 5%;
}

.create-course-container > .coursetab > .tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  column-gap: 4%;
  width: 84%;
  min-height: 90px;
  box-shadow: 0 3px 3px #ccc;
  border: none;
  background: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  cursor: pointer;
  margin: 5px auto;
}

.disabledTab {
  cursor: not-allowed !important;
  background: rgb(230, 230, 230) !important;
}

.create-course-container {
  display: flex;
  width: 100%;
  height: 69%;
}

.outerstudent {
  display: flex;
  flex-direction: column;
  width: 374px;
  background-color: #fff;
  row-gap: 2%;
  height: 100vh;
  position: fixed;
  overflow: auto;
  left: 0;
  padding-top: 6rem;
}

.innercirc {
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  background: #f3cc4d;
}
.ql-align-center {
  text-align: center;
}
.inpara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  text-transform: capitalize;
  color: #717070;
  margin: 0px;
}

.pdfwrap {
  display: flex;
  width: 250px;
  margin-top: -28%;
  margin-left: -10%;
}

.icons {
  width: 50px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-transform: capitalize;
  color: #717070;
}

.innercontent {
  width: calc(100% - 120px);
  margin-left: 120px;
  margin-right: auto;
  margin-top: 80px;
  padding: 30px;
  height: auto;
}

.textwrap {
  width: 100%;
}

.langwrap {
  width: 85%;
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.langparacour {
  width: 241px;
  height: 62px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20.8026px;
  line-height: 150%;
  text-transform: capitalize;
  color: #540375;
}

.percentcour {
  margin-top: 2%;
  width: 120px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #9f9f9f;
}

.indeximg {
  display: flex;
  justify-content: center;
  align-items: baseline;
  column-gap: 7%;
}

.indexwrap {
  display: flex;
  justify-content: space-between;
  padding: 3%;
  background: #ffffff;
  box-shadow: 0px 3px 3px #ccc;
  margin-bottom: 1%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.textbox {
  padding: 4%;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  row-gap: 5%;
  background-color: #fff;
}

.engpara {
  width: 859px;
  height: 69px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 200%;
  text-transform: capitalize;
  color: #4d1a4e;
}

.outerparawrap {
  margin-top: 4%;
}

.outerparawrap > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.listpara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.communicate {
  width: 829px;
  height: 69px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-transform: capitalize;
  color: #4d1a4e;
}

.surveybox {
  padding: 3%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  min-height: 1000px;
}

.outersurvey {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0 4px 4px #ccc;
}

.innersurvey {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1%;
  width: 100%;
  height: 44px;
  background: #d6bfda;
}

.qnpara {
  width: 811.13px;
  height: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26.8182px;
  text-transform: capitalize;
  color: #717070;
}

.descpara {
  width: 881.22px;
  height: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26.8182px;
  text-transform: capitalize;
  color: #000000;
}

.outerqnbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  row-gap: 13px;
  margin-top: 2%;
  padding: 20px;
}

.inputbox {
  box-sizing: border-box;
  width: 26%;
}

.continuebtn {
  width: 15%;
  background-color: #540375;
  color: #fff;
  margin-bottom: 4%;
}

.videparawrap {
  width: 995px;
  height: 69px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-transform: capitalize;
  color: #4d1a4e;
}

.ladyvideoimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: 500.9px;
  background: #f8f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pdfwrapcour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 4px #ccc;
}

.readpara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-transform: capitalize;
  color: #4d1a4e;
}

iframe {
  height: 100%;
}

.pdfdetail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
}

.pdfrender {
  margin-top: -20%;
}

.quizwarpcon {
  display: flex;
  flex-direction: column;
  row-gap: 4%;
  margin-bottom: 2%;
}

.qnquizwrap {
  display: flex;
  column-gap: 2%;
}

.qnquizwrap {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  margin-bottom: 2%;
}
.allqn .score {
  width: 100%;
}
.allqn .score > p {
  display: inline-block;
  margin-right: 1rem;
  font-size: 20px;
  color: grey;
}

.allqn .score span {
  font-weight: 600;
  font-size: 1.5rem;
}
.allqn .score span.correct {
  color: #98d8aa;
}

.allqn .score span.incorrect {
  color: #f15a59;
}

.qnask .options-box {
  /* border: 1px solid rgba(128, 128, 128, 0.438); */
  background: whitesmoke;
  padding: 2rem;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.qnask {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.qnask .options-box .option {
  border: 2px solid rgba(160, 127, 167, 0.438);
  width: 100%;
  background: #fff;
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 3rem;
  height: auto;
}

.qnask .options-box .option > p {
  margin-bottom: 0;
}

.qnask button {
  padding: 10px 0px;
  border-radius: 10px;
  background: rgba(77, 26, 78, 0.582);
  color: white;
  font-weight: 500;
  border: none;
  width: 8rem;
  margin-right: 10px;
  margin-top: 10px;
}

.qnask .btns {
  margin-left: auto;
  text-align: right;
}

.qnask button:hover {
  transition: 200ms ease-in-out;
  background: rgba(77, 26, 78, 0.282);
  color: rgba(77, 26, 78, 0.782);
}

.quizopt {
  display: flex;
  column-gap: 19%;
}

.qnparaquiz {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 21.49px;
  text-transform: capitalize;
  color: #717070;
}

.secquiz {
  margin-left: 6.7%;
}

.quizbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
}

.rightimg {
  border: 0.943925px solid #f39f30;
  padding: 5%;
  border-radius: 26px;
  padding-top: 6%;
  margin-right: 2%;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .outerstudent {
    width: 280px;
  }
  .innercontent {
    width: calc(100% - 45px);
    margin-left: 45px;
    padding: 15px;
  }
  .innercontent img {
    max-width: 100%;
  }
  .inpara {
    font-size: 14px;
  }
  .create-course-container > .coursetab > .tab {
    width: 90%;
    min-height: auto;
  }
}
@media only screen and (max-width: 768px) {
  .wrappercourse {
    padding: 0 !important;
    flex-direction: column;
    row-gap: 1rem;
  }

  .outerstudent {
    row-gap: 1rem;
  }

  .innercontent {
    width: 88%;
  }

  .engpara {
    width: 100%;
    font-size: 14px;
  }

  .textbox > img {
    width: 100%;
  }

  .outerparawrap > ul,
  .outerparawrap > p {
    font-size: 14px;
  }

  .communicate {
    width: 100%;
    font-size: 14px;
  }

  .outersurvey {
    width: 100%;
  }

  .innersurvey {
    width: 100%;
  }

  .qnpara {
    width: 100%;
    font-size: 14px;
  }

  .descpara {
    width: 100%;
    font-size: 14px;
  }

  .continuebtn {
    width: 100%;
  }

  .pdfwrapcour {
    width: 100%;
  }

  .readpara {
    width: 100%;
    font-size: 14px;
  }

  .videparawrap {
    width: 100%;
    font-size: 14px;
  }

  .ladyvideoimg {
    width: 100%;
    height: 32vh;
  }

  .ladyvideoimg > img {
    width: 90%;
  }

  .quizbox {
    margin-top: 169%;
  }

  .qnquizwrap {
    width: 100%;
  }

  .qnnumber {
    width: 5% !important;
  }

  .qnquizwrap > p {
    width: 100%;
    font-size: 14px;
  }

  .qnparaquiz {
    width: auto;
    font-size: 14px;
  }
  .quizopt {
    flex-direction: column;
  }

  .quizopt > div {
    display: flex;
  }

  .secquiz {
    margin-left: 0%;
  }
}
